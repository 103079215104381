<template>
    <div class="container">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <button class="simple-button-letz">CAMBIAR CONTRASEÑA</button>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <button @click="requestDeleteAccount" class="simple-button-letz-pink">ELIMINAR MI CUENTA</button>
            </div>
        </div>
    </div>
</template>
<script>
import { router } from '@/_helpers';
import Swal from 'sweetalert2';
import { accountService, authenticationService } from '@/_services';
export default {
    name: 'Security',
    data() {
        return {
        }
    },
    components: {

    },
    methods: {
        eliminarHorario() {
            router.push('/travels');
        },
        requestDeleteAccount() {
            Swal.fire({
                title: 'Eliminar cuenta',
                text: "Si eliminas tu cuenta permanentemente no podras recuperar tus viajes y experiencias, ¿deseas continuar?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                //cancelButtonColor: '#d33',
                confirmButtonText: 'Si, continuar'
            }).then((result) => {
                if (result.isConfirmed) {
                    accountService.requestDelete().then((resemail) => {
                        console.log(resemail);
                        Swal.fire({
                            title: 'Eliminar cuenta',
                            html: 'Para eliminar tu cuenta ingresa el codigo que se envio a tu correo ' + resemail.data + '</br> ' +
                                `<input type="text" id="confirmtext" class="swal2-input" placeholder="CODIGO">`,
                            confirmButtonText: 'Confirmar',
                            cancelButtonText: 'Cancelar',
                            focusConfirm: false,
                            showCancelButton: true,
                            preConfirm: async () => {
                                const confirmtext = Swal.getPopup().querySelector('#confirmtext').value
                                try {
                                    const resConfirm = await accountService.confirmDelete(confirmtext);
                                    console.log("resConfirm");
                                    console.log(resConfirm);
                                    if (resConfirm.code == 0) {
                                        authenticationService.logout();
                                    } else {
                                        Swal.showValidationMessage(`El código que ingresaste es incorrecto`);
                                    }
                                    return { confirmtext: confirmtext };
                                } catch (error) {
                                    Swal.showValidationMessage(`Ocurrió un error al confirmar la eliminación: ${error.message}`);
                                    return false; // Returning false will keep the dialog open
                                }

                            }
                        }).then((result) => {
                            if (result.isConfirmed) {
                                Swal.fire('Su cuenta y datos asociados han sido eliminados', '', 'success')
                            } else {
                                Swal.fire('Gracias por quedarte!', '', 'info')
                            }
                        })
                    });


                }
            })
        },
        deleteUser: function () {
            Swal.fire({
                title: 'Eliminar cuenta',
                html: 'Para eliminar tu cuenta escribe <b>ELIMINAR MI CUENTA DE FORMA PERMANENTE</b></br> ' +
                    `<input type="text" id="confirmtext" class="swal2-input" placeholder="ELIMINAR MI CUENTA DE FORMA PERMANENTE">`,
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                focusConfirm: false,
                showCancelButton: true,
                preConfirm: () => {
                    const confirmtext = Swal.getPopup().querySelector('#confirmtext').value
                    if (!confirmtext || confirmtext != 'ELIMINAR MI CUENTA DE FORMA PERMANENTE') {
                        Swal.showValidationMessage(`Por favor escribe "ELIMINAR MI CUENTA DE FORMA PERMANENTE"`)
                    }
                    return { confirmtext: confirmtext }
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire('Su cuenta ha sido eliminada', '', 'success')
                } else {
                    Swal.fire('Gracias por quedarte!', '', 'info')
                }
            })

        }
    }

}
</script>
<style></style>