<template>
  <div>
    <div class="container">
      <div class="container-settings">
        <ul class="menu">
          <li class="menu-item" :class="{ active: activeItem === 'profile' }" @click="profile">Perfil</li>
          <li class="menu-item" :class="{ active: activeItem === 'preference' }" @click="preference">Preferencias</li>
          <li class="menu-item" :class="{ active: activeItem === 'security' }" @click="security">Seguridad</li>
        </ul>
        <div class="content">
          <!-- Contenido dinámico va aquí -->
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { router } from '@/_helpers';
  export default {
    props: ['imageness'],
    data() {
      return {
        activeItem: 'profile', // Inicialmente, el elemento 'profile' está activo
      }
    },
    methods: {
      setActiveItem(itemId) {
        this.activeItem = itemId;
      },
      profile() {
        if(this.activeItem != 'profile'){
          this.activeItem = 'profile';
          router.push('profile');
        }
      },
      preference() {
        if(this.activeItem != 'preference'){
          this.activeItem = 'preference';
          router.push('preference');
        }
      },
      security() {
        if(this.activeItem != 'security'){
          this.activeItem = 'security';
          router.push('security');
        }
      }
    },
    created() {
      this.$emit('menuCheck');
      //router.push('settings/profile');
    },
    computed: {
    }
  }

</script>
<style>
  body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
  }

  .container-settings {
    display: flex;
    height: calc(100vh - 100px);
  }

  .menu {
    flex: 1;
    background-color: #fff;
    border-right: 1px solid #ccc;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .menu-item {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .menu-item:hover {
    background-color: #ffcccc;
  }

  .menu-item.active {
    background-color: #ccebff;
    border-left: 4px solid #0066cc;
  }

  .content {
    flex: 4;
    background-color: #f3f3f3;
    padding: 20px;
  }
  .separador-nav{
    height: 60px;
  }
</style>