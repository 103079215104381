<template>
    <div>
        <Navegador></Navegador>
        <div class="d-flex align-items-center" >

        </div>
        <div class="row">
            <div class="col-6 img2">
                <div>
                    <img src="https://letzgeneral-public.s3.us-east-1.amazonaws.com/webletztrav/LetZTrav_nbg-010.png">
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
                <div class="login-content">
                    <form class="formularioletz">
                        <b class="title">Agregar Nueva Experiencia</b>
                        <hr size="10px" color="black" />
                        <div class="input-divf">
                            <input class="form-control textForm2" id="name" v-model="name" type="text" placeholder="Nombre">
                        </div>
                        <div class="input-divf">
                            <input class="form-control textForm2" id="direction" v-model="direction"  type="text" placeholder="Ubicacion">
                        </div>
                        <div class="input-divf row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <input class="form-control textForm2" id="priceMin" step="500" v-model="priceMin"  value="500" type="number" placeholder="Precio Minimo">
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <input class="form-control textForm2" id="priceMax" step="500" v-model="priceMax"  value="1000" type="number" placeholder="Precio Maximo">
                            </div>
                        </div>
                        <div class="input-divf">
                            <select class="form-control textForm2" data-live-search="true" v-model="category">
                                <option disabled value="">Seleccione una categoría</option>
                                <option v-for="option in categories" :key="option.id" v-bind:value="option.id">
                                    {{ option.name }}
                                </option>
                            </select>
                        </div>
                        <a class="txt" href="#">¿No encontraste la categoria que buscabas?</a>
                        <a class="txtCrear" @click="nuevaCategoria">+ Agregar categoria</a>
                        <hr size="10px" color="black" />
                        <input @click="crearExperiencia" class="btnSiguiente" value="Siguiente">
                    </form>
                </div>
            </div>
        </div>
        <br>
        <!--<div class="row">
            <div class="col-12">
                <upload-media 
            server="http://localhost:8080/api/file/temporal">
            </upload-media>
            </div>
            
        </div>-->
        <div class="row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-12 col-xl-12">
                <UploadImage 
            server="https://api-dev.letztrav.com/api/file/temporal"
            maximo=15
            @imagenes="actualizarImagenes"
            >

            </UploadImage>
            </div>
            
        </div>


        <!--<div class="container">
            <div class="img2">
                <img src="../assets/LetZTrav_nbg-010.png">
            </div>
            <div class="login-content">
                <form class="formularioletz">
                    <b class="title">Agregar Nueva Experiencia</b>
                    <hr size="10px" color="black" />
                    <div class="input-divf">
                        <input class="form-control textForm2" id="name" v-model="name" type="text" placeholder="Nombre">
                    </div>
                    <div class="input-divf">
                        <input class="form-control textForm2" id="location" v-model="location"  type="text" placeholder="Ubicacion">
                    </div>
                    <div class="input-divf row">
                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <input class="form-control textForm2" id="priceMin" step="500" v-model="priceMin"  value="500" type="number" placeholder="Precio Minimo">
                        </div>
                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <input class="form-control textForm2" id="priceMax" step="500" v-model="priceMax"  value="1000" type="number" placeholder="Precio Maximo">
                        </div>
                    </div>
                    <div class="input-divf">
                        <select class="form-control textForm2" data-live-search="true" v-model="category">
                            <option disabled value="">Seleccione una categoría</option>
                            <option v-for="option in categories" :key="option.id" v-bind:value="option.id">
                                {{ option.name }}
                            </option>
                        </select>
                    </div>
                    <a class="txt" href="#">¿No encontraste la categoria que buscabas?</a>
                    <a class="txtCrear" @click="nuevaCategoria">+ Agregar categoria</a>
                    <hr size="10px" color="black" />
                    <input @click="crearExperiencia" class="btnSiguiente" value="Siguiente">
                </form>
            </div>
            <div class="row">
                <vue-upload-multiple-image
                @upload-success="uploadImageSuccess"
                @before-remove="beforeRemove"
                @edit-image="editImage"
                @data-change="dataChange"
                :data-images="images"
                dragText="Arrastrar imágenes (múltiples)"
                :maxImage="maxImages"
                browseText="(o) Seleccionar"
                primaryText="Por defecto"
                markIsPrimaryText="Establecer por defecto"
                popupText="Esta imagen se mostrará por defecto"
                dropText="Deja tus archivos aquí..."
                ></vue-upload-multiple-image>
            </div>
        </div>-->
    </div>
</template>
    
<script>
    import Navegador from '@/components/Navegador'
    import { router } from '@/_helpers';
    //import { authenticationService } from '@/_services';
    import { experienceService } from '@/_services';
    import { categoryService } from '@/_services';
    import Swal from 'sweetalert2';
    //import {UploadMedia} from "vue-media-upload";
    import UploadImage from '@/components/utils/UploadImage'
    

    
    export default {
        data() {
            return {
                name:null,
                direction:null,
                priceMin:null,
                priceMax:null,
                category:'',
                categories:[],
                maxImages:15,
                images:[],
                urlImagenes:[]
            }
        },
        created() {
            categoryService.getAll()
                .then(x => this.categories = x);
                
        },
        components: {
            Navegador,
            //UploadMedia,
            UploadImage
        },
        methods: {
            actualizarImagenes(urls){
                this.urlImagenes=urls;
                console.log("arreglo de imagenes");
                console.log(this.urlImagenes);
            },
            //logout: authenticationService.logout,
            nuevaCategoria(){
                console.log("creando categoria");
                router.push('/nueva/categoria');
            },
            crearExperiencia(){
                console.log("creando nueva experiencia:::,,");
                var request={
                    name:this.name,
                    direction:this.direction,
                    prices:{min:this.priceMin, max:this.priceMax},
                    category:this.category,
                    imagesNew:this.urlImagenes
                };
                console.log(request);
                experienceService.create(request).then(() => {
                    console.log("regresanddoooooo");
                    Swal.fire(
              'Exito!',
              'Se ha registrado la experiencia correctamente',
              'success'
				);
                    router.push('../');
                })
                .catch(error => {
                    this.error = error;
                    this.loading = false;
                });
            },
            uploadImageSuccess(formData, index, fileList) {
                console.log("subiendo fotooooss!!!");
                console.log('data', formData, index, fileList)
                // Upload image api
                // axios.post('http://your-url-upload', { data: formData }).then(response => {
                //   console.log(response)
                // })
            },
            beforeRemove (index, done, fileList) {
                console.log('index', index, fileList)
                done()
            },
            editImage (formData, index, fileList) {
                console.log('edit data', formData, index, fileList)
            },
            dataChange (data) {
                console.log(data)
            }
        }
    }
    </script>
<style>
    .preview-image{
        width: 500px;
        height: 500px;
    }
    .show-image{
        width: 100%;
        height: 100%;
    }
    .show-img{
        width: 100%;
        height: 100%;
    }
    a.txt{
        display: block;
        text-align: center;
        text-decoration: none;
        color: #999;
        font-size: 0.7rem;
        transition: .3s;
    }

    .img2{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .img2 img{
        width: 400px;
    }

    @media screen and (max-width: 1000px){
	.formm{
		width: 290px;
	}

	.login-content h2{
        font-size: 2.4rem;
        margin: 8px 0;
	}
}

@media screen and (max-width: 900px){
	.container{
		grid-template-columns: 1fr;
	}

	.img2{
		display: none;
	}

	.wave{
		display: none;
	}

	.login-content{
		justify-content: center;
	}
}

    

    a.txtCrear{
        display: block;
        text-align: center;
        text-decoration: none;
        color: #3B5998;
        font-size: 0.9rem;
        transition: .3s;
    }
    .title{
        color: #00369b;
        font-size: large;
    }
    .input-divf{
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .textForm2{
        border-radius: 10px;
        border: 1px solid #0aaebd;
        font-size:medium;
        padding-right: 10px;
        padding-left: 10px;
    }
    .textForm3{
        border-radius: 10px;
        border: 1px solid #0aaebd;
        font-size:medium;
        padding-right: 10px;
        padding-left: 10px;
        margin-right: 1px;
        margin-left: 1px;
        width: 49%;
    }
    .formularioletz{
        width: 50%;
    }
    .btnSiguiente{
        text-align: center;
        display: block;
        width: 100%;
        height: 35px;
        border-radius: 10px;
        outline: none;
        border: none;
        background-image: linear-gradient(to right, #0aaebd, #0aaebd, #68d9e4);
        background-size: 200%;
        font-size: 1rem;
        color: #fff;
        font-family: 'Poppins', sans-serif;
        margin: 1rem 0;
        cursor: pointer;
        transition: .5s;
    }
    .btnSiguiente:hover{
        background-position: right;
    }
</style>