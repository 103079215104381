import axios from 'axios';

import { authenticationService } from '@/_services';
//import { router } from '@/_helpers';
export function errorInterceptor() {
    axios.interceptors.response.use(null, (error) => {
        const { response } = error;
        if (!response) {
            // network error
            console.log("error");
            console.error(error);
            return;
        }
        console.log("REVISANDO");
        console.log(response);
        console.log(authenticationService.authenticationValue);

        if ([401, 403].includes(response.status) && authenticationService.authenticationValue) {
            console.log("deslogueando por 403")
            // auto logout if 401 or 403 response returned from api
            authenticationService.logout();
            return
        }

        if ([404].includes(response.status) && !authenticationService.authenticationValue) {
            console.log("POS - enviando a registro")
            if(response.data.message == 'UNREGISTERED_USER') {
                return response;
            }
            return
        }
        if(!authenticationService.authenticationValue) {
            console.log("deslogueando por que no existe authenticationValue")
            // auto logout if 401 or 403 response returned from api
            authenticationService.logout();
            return
        }

        /*if (!authenticationService.authenticationValue) {///REVISAR SI ES NECESARIO ENVIAR AL LOGIN
            console.log("enviando a login")
            router.push('/login');
            return;
        }*/

        const errorMessage = response.data?.message || response.statusText;
        console.error('ERROR:', errorMessage);
        return response;
    });
}
