//import { authenticationService } from '@/_services';

export function authGuard(to, from, next) {
    //const authentication = authenticationService.authenticationValue;
    const storedAuthentication = sessionStorage.getItem('authentication');
    console.log("revisando autenticacion");
    if (!storedAuthentication) {
        console.log("no esta AUTENTICADO");
        // not logged in so redirect to login page with the return url
        return next({ path: '/login', query: { returnUrl: to.fullPath } });
    }
    console.log(next)

    next();
}