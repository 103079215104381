<template>
  <div>
    <div class="container d-none d-lg-block">
      <div class="row">
        <div class="d-none d-lg-block col-lg-3">
          <img v-if="infoUser.photo == ''" src="@/assets/images/foto-icon.png" class="img-fluid v" alt="">
          <img v-if="infoUser.photo != ''" :src=infoUser.photo alt="" class="img-fluid img-round">
          <br>
        </div>
        <div class="col-12 col-lg-9">
          <div class="dataUser2 aling-l">
            <h2 class="userStyle">{{ infoUser.firstName }} {{ infoUser.lastName }}</h2>
            <h4>{{ infoUser.country }}</h4>
            <h4>{{ infoUser.biography }}</h4>
          </div>
          <div class="d-none d-lg-block buttonsContent">
            <div class="row">
              <div class="col-md-4 aling-l">
                <button @click="goTravels" class="simple-button-letz">Mis viajes</button>
              </div>
              <div class="col-md-4">
                <button @click="goExperiences" class="simple-button-letz">Mis experiencias</button>
              </div>
              <div class="col-md-4 aling-r">
                <button @click="goMaps" class="button-active">Mi mapa</button>
              </div>
            </div>
          </div>
          <div class="d-lg-none buttonsContent row">
            <div class="col-6">
              <div class="input-divf text-left">
                <select v-model="viewToShow" id="viewToShow" class="form-control textForm2">
                  <option value="TRAVELS">Mis viajes</option>
                  <option value="EXPERIENCES">Mis experiencias</option>
                  <option value="MAPS">Mi mapa</option>
                </select>
              </div>
            </div>
          </div>
          <div class="d-none d-lg-block">
            <GmapMap :options="mapOptions" :center="center" :zoom="zoom" map-type-id="terrain"
              style="width: 100%; height: 500px">
              <GmapMarker :key="index" v-for="(place, index) in places" :position="place.coordinates" :title="place.to"
                :icon="{
                  url: require('@/assets/images/logo-icon.png'),
                  scaledSize: { width: 32, height: 32 }
                }" :scaledSize="place.size" :clickable="true" @click="toggleInfo(place)" />
            </GmapMap>
          </div>
        </div>
      </div>
    </div>
    <!--AQUI COMIENZA EL MOVIL-->
    <div class="d-lg-none">
      <div class="row">
        <div class="col-12">
          <div class="dataUser2 aling-l">
            <h2 class="userStyle">{{ infoUser.firstName }} {{ infoUser.lastName }}</h2>
            <h4>{{ infoUser.country }}</h4>
            <h4>{{ infoUser.biography }}</h4>
          </div>
          <div class="d-lg-none buttonsContent row">
            <div class="col-12">
              <div class="input-divf text-left">
                <select v-model="viewToShow" id="viewToShow" class="form-control textForm2">
                  <option value="TRAVELS">Mis viajes</option>
                  <option value="EXPERIENCES">Mis experiencias</option>
                  <option value="MAPS">Mi mapa</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-lg-none">
        <GmapMap :options="mapOptions" :center="center" :zoom="zoom" map-type-id="terrain"
          style="width: 100%; height: 500px">
          <GmapMarker :key="index" v-for="(place, index) in places" :position="place.coordinates" :title="place.to" :icon="{
            url: require('@/assets/images/logo-icon.png'),
            scaledSize: { width: 32, height: 32 }
          }" :scaledSize="place.size" :clickable="true" @click="toggleInfo(place)" />
        </GmapMap>
      </div>
    </div>
  </div>
</template>
  
<script>
import { travelService } from '@/_services';
import { authenticationService } from '@/_services';

export default {
  props:['idUser'],
  data() {
    return {
      viewToShow: 'MAPS',
      infoUser: {},
      center: { lat: 20, lng: 10 },
      zoom: 2,
      places: [
        /*{
          name: 'Lugar 1',
          coordinates: { lat: 40.7128, lng: -74.0060 },
        },
        {
          name: 'Lugar 2',
          coordinates: { lat: 34.0522, lng: -118.2437 },
        },
        {
          name: 'Huatulco',
          coordinates: { lat: 15.749695, lng: -96.124888 },
        },
        {
          name: 'Cancún',
          coordinates: { lat: 21.158963, lng: -86.846659 },
        },*/
      ],

      mapOptions: {
        styles: [
          {
            // featureType: 'water',
            // elementType: 'geometry',
            // stylers: [{ color: '#0078D4' }],
          },
        ],
      },
    }
  },
  methods: {
    toggleInfo(item) {
      this.$router.push('/travels/photos/' + item.id);
    },
    async getInfoUser() {
      const res = await authenticationService.getInfoUser(this.idUser);
      this.infoUser = {
        firstName: res.data.details.firstName,
        lastName: res.data.details.lastName,
        country: res.data.details.country,
        biography: res.data.details.biography,
        photo: res.data.details.profilePhoto.larger
      }
    },
    async getGeoMakers() {
      const res = await travelService.getGeoMakers(this.idUser);
      console.log("PUNTOS DEL MAPA");
      console.log(res.data);
      this.places = this.transformData(res.data);
    },
    transformData(data) {
      const transformedData = [];
      for (const place of data) {
        var obj = {
          name: place.to,
          coordinates: {
            lat: place.toLocation.latitude,
            lng: place.toLocation.longitude,
          },
          id: place.id
        }
        transformedData.push(obj);
      }
      return transformedData;
    },
    goTravels() {
      console.log('go travel');
      if (this.idUser == null || this.idUser == '') {
        this.$router.push('/travels');
      } else {
        this.$router.push('/user/'+this.idUser+'/travels');
      }
    },
    goExperiences() {
      console.log('go experiences');
      if (this.idUser == null || this.idUser == '') {
        this.$router.push('/experiences');
      } else {
        this.$router.push('/user/'+this.idUser+'/experiences');
      }
    },
    goMaps() {
      console.log('go experiences');
      if (this.idUser == null || this.idUser == '') {
        this.$router.push('/maps');
      } else {
        this.$router.push('/user/'+this.idUser+'/maps');
      }
    }
  },
  watch: {
    viewToShow() {
      if (this.viewToShow == 'TRAVELS') {
        this.goTravels()
      } else if (this.viewToShow == 'EXPERIENCES') {
        this.goExperiences()
      } else if (this.viewToShow == 'MAPS') {
        this.goMaps()
      }
    }
  },
  created() {
    console.log("INICIANDO MAPS");
    this.getGeoMakers();
    this.getInfoUser();
    this.$emit('menuCheck');

  }
}
</script>
<style>
.contenedor-movil {
  display: none;
}

@media screen and (max-width: 900px) {
  .contenedor-movil {
    display: block;
  }

  .contenedor-web {
    display: none;
  }
}

.pading-boot {
  padding-bottom: 10px;
}

.rosaletztrav {
  background-color: #f19dc0;
  border-color: #f19dc0;
}

.azulletztrav {
  background-color: #003686;
  border-color: #003686;
}

.pagination {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.vistamovil {
  display: none;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}

.vistaweb {
  padding-top: 40px;
}



@media screen and (max-width: 900px) {

  .vistaweb {
    display: none;
  }

  .vistamovil {
    display: flex;
  }

}

.buttonsContent {
  padding-top: 20px;
  margin-bottom: 35px;
}

#description-container {
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #fff;
}

.description {
  max-width: 200px;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #fff;
}

.dataUser {
  margin-left: 20px;
  padding-top: 170px;
}

.userStyle {
  color: #0032a0;
  font-weight: 600;
}

.img-foto {
  padding-top: 155px;
  width: 80%;
}

.button-travel-add {
  height: 40px;
  width: 190px;
  margin: 0 5px;
  background-color: #0032a0;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  margin-top: 40px;
}
</style>