import { BehaviorSubject } from 'rxjs';
import axios from 'axios';

const baseUrl = `${process.env.VUE_APP_API_URL}/api/core/user`;
const userSubject = new BehaviorSubject(null);


export const userService = {
    saveProfilePicture,
    getAll,
    create,
    getById,
    getStadistics,
    update,
    delete: _delete,
    activeUser,
    user: userSubject.asObservable(),
    get userValue () { return userSubject.value; }
};

function saveProfilePicture(file) {
    let formData = new FormData
    formData.set('file', file)
    return axios.put(`${baseUrl}/profilepic`, formData, {withCredentials: true})
        .then(response => response);
        
}

function getAll(pagina, itemsPerPage) {
    console.log("buscando experiencias");
    console.log(pagina);
    console.log(itemsPerPage);
    return axios.get(`${baseUrl}/${pagina}/10`, {withCredentials: true})
        .then(response => response.data);
}

async function create(params) {
    const response = await axios.post(`${baseUrl}`, params , {withCredentials: true});
    let responseData = response.data;
    return responseData;
}

async function update(params) {
    const response = await axios.put(`${baseUrl}`, params, {withCredentials: true});
    let responseData = response.data;
    return responseData;
}

function getById(id) {
    console.log("buscando experiencia: "+id);
    return axios.get(`${baseUrl}/${id}`, {withCredentials: true})
        .then(response => response.data);
}

function getStadistics(url) {
    return axios.get(`${process.env.VUE_APP_API_URL}`+url, {withCredentials: true})
        .then(response => response);
}

async function activeUser(code, email) {
    return axios.get(`${process.env.VUE_APP_API_URL}/api/users/activations/`+code+`?email=`+email, {withCredentials: true})
        .then(response => response);
}



async function _delete(id) {
    await axios.delete(`${baseUrl}/${id}`, {withCredentials: true});
    /*if (id === experienceSubject.value?.id) {
        // auto logout if the logged in account was deleted
        logout();
    }*/
}