import axios from 'axios';

import { authenticationService } from '@/_services';

export function jwtInterceptor() {
    axios.interceptors.request.use(request => {
        console.log("interceptor")
        // add auth header with jwt if account is logged in and request is to the api url
        const account = authenticationService.authenticationValue;
        console.log(account)
        const isLoggedIn = account?.token;
        console.log(isLoggedIn)
        const isApiUrl = request.url.startsWith(process.env.VUE_APP_API_URL);
        console.log(isApiUrl)
        if (isLoggedIn && isApiUrl) {
            request.headers.common.Authorization = `Bearer ${account.token}`;
        }

        return request;
    });
}