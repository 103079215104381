import Vue from 'vue';
import Router from 'vue-router';

//import Home from '@/home/Home';
import PanelTravels from '@/home/PanelTravels';
import HomeFriends from '@/home/HomeFriends';
import PanelExperiences from '@/home/PanelExperiences';
import EditExperience from '@/home/EditExperience';
import ViewExperience from '@/home/ViewExperience';
//import Experiences from '@/experiences/Experiences';
import MyMaps from '@/maps/MyMaps';
import Login from '@/components/Login';
import Register from '@/components/Register';
import ConfirmAccount from '@/components/ConfirmAccount';
import NuevaExperiencia from '@/components/NuevaExperiencia';
import NuevaCategoria from '@/components/NuevaCategoria';
import Settings from '@/components/Settings';
import Profile from '@/components/Profile';
import Preference from '@/components/Preference';
import Security from '@/components/Security';
import Items from '@/components/Items'
import ItemsExperiences from '@/components/ItemsExperiences'
import GaleryLetztravTravel from '@/components/utils/GaleryLetztravTravel'
//import GaleryLetz from '@/components/utils/GaleryLetz'
import GaleryLetztravExperience from '@/components/utils/GaleryLetztravExperience'
import AddTravel from '@/home/AddTravel'
import AddExperience from '@/experiences/AddExperience'
import { authGuard } from '@/_helpers';

Vue.use(Router);

const routes = [
    {
        path: '/travels',
        component: PanelTravels,
        children: [
            { path: '', component: Items },
            { path: 'photos/:id', component: GaleryLetztravTravel },
            { path: 'create', component: AddTravel },
        ],
        beforeEnter: authGuard
    },
    {
        path: '/experiences',
        component: PanelExperiences,
        children: [
            { path: '', component: ItemsExperiences },
            { path: 'photos/:id', component: GaleryLetztravExperience },
            { path: 'create', component: AddExperience },
        ],
        beforeEnter: authGuard
    },
    { path: '/maps', component: MyMaps, beforeEnter: authGuard },
    { path: '/edit/:id', component: EditExperience },
    { path: '/view/:id', component: ViewExperience },
    { path: '/login', component: Login },
    { path: '/register', component: Register },
    { path: '/account/confirm/:email', component: ConfirmAccount },
    { path: '/nueva/experiencia', component: NuevaExperiencia },
    { path: '/nueva/categoria', component: NuevaCategoria },
    {
        path: '/settings',
        component: Settings,
        children: [
            { path: 'profile', component: Profile },
            { path: 'security', component: Security },
            { path: 'preference', component: Preference },
        ]
    },
    {
        path: '/user/:id',
        component: HomeFriends,
        children: [
            {
                path: 'travels',
                component: PanelTravels,
                children: [
                    { path: '', component: Items },
                    { path: 'photos/:idTravel', component: GaleryLetztravTravel },
                ],

            },
            {
                path: 'experiences',
                component: PanelExperiences,
                children: [
                    { path: '', component: ItemsExperiences },
                    { path: 'photos/:idTravel', component: GaleryLetztravExperience },
                ],

            },
            {
                path: 'maps',
                component: MyMaps,
                beforeEnter: authGuard
            },
        ],
        beforeEnter: authGuard
    },

    // otherwise redirect to home
    { path: '*', redirect: '/travels' }
];

export const router = new Router({
    mode: 'history',
    linkActiveClass: 'active',
    routes
});