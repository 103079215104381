<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="d-none d-lg-block col-lg-3">
          <img v-if="infoUser.photo == ''" src="@/assets/images/foto-icon.png" class="img-fluid img-round" alt="">
          <img v-if="infoUser.photo != ''" :src=infoUser.photo class="img-fluid img-round" alt="">
          <br>
          <button v-if="viewExperiences  && idUser==null" @click="goAddExperience" class="button-travel-add">
            <i class="bi bi-plus-circle"></i>
            &nbsp; Agregar experiencia
          </button>
        </div>
        <div class="col-12 col-lg-9">
          <div class="dataUser2 aling-l">
            <h2 class="userStyle">{{ infoUser.firstName }} {{ infoUser.lastName }}</h2>
            <h4>{{ infoUser.country }}</h4>
            <h4>{{ infoUser.biography }}</h4>
          </div>
          <div class="d-none d-lg-block buttonsContent">
            <div class="row">
              <div class="col-md-4 aling-l">
                <button @click="goTravels" class="simple-button-letz">Mis viajes</button>
              </div>
              <div class="col-md-4">
                <button @click="goExperiences" class="button-active">Mis experiencias</button>
              </div>
              <div class="col-md-4 aling-r">
                <button @click="goMaps" class="simple-button-letz">Mi mapa</button>
              </div>
            </div>
          </div>
          <div class="d-lg-none buttonsContent row">
            <div class="col-6">
                <div class="input-divf text-left">
                  <select v-model="viewToShow" id="viewToShow" class="form-control textForm2">
                    <option value="TRAVELS">Mis viajes</option>
                    <option value="EXPERIENCES">Mis experiencias</option>
                    <option value="MAPS">Mi mapa</option>
                  </select>
                </div>
              </div>
              <div class="col-6">
                <button v-if="viewExperiences  && idUser==null"  @click="goAddExperience" class="button-travel-add">
                  <i class="bi bi-plus-circle"></i>
                  &nbsp; Agregar experiencia
                </button>
              </div>
          </div>
          <router-view  :idUser="idUser"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
//import ModalEditExperience from '@/components/ModalEditExperience'
//import CardExperience from '@/components/CardExperience'
//import vPagination from 'vue-plain-pagination'
import { router } from '@/_helpers';
import { experienceService } from '@/_services';
import Swal from 'sweetalert2';
import { authenticationService } from '@/_services';

export default {
  props:['idUser'],
  data() {
    return {
      viewToShow: 'EXPERIENCES',
      viewExperiences: true,
      ruta: '',
      travels: [],
      cargandoDatos: true,
      puedeBuscarMas: false,
      experiences: null,
      experiencesMovil: null,
      pagina: 1,
      totalPaginas: 5,
      pageInfo: {
        number: 1,
        size: 10,
        numberOfElements: 2,
        totalElements: 2,
        totalPages: 1
      },
      bootstrapPaginationClasses: {
        ul: "pagination",
        li: "page-item",
        liActive: "active",
        liDisable: "disabled",
        button: "page-link",
      },
      paginationAnchorTexts: {
        prev: "Anterior",
        next: "Siguiente",
      },
      infoUser: {}
    }
  },
  created() {
    this.getInfoUser();
    this.$emit('menuCheck');
    experienceService.getAll(0)
      .then(x => {
        console.log('x::');
        console.log(x);
        // this.experiences = x.data;
        // this.experiencesMovil = x.data;
        // this.pageInfo = x.pageInfo;
        // this.pagina = x.pageInfo.number + 1;
        // this.puedeBuscarMas = true;
        // this.cargandoDatos = false;
      });
    // window.addEventListener('scroll', this.handleScroll);
  },
  // destroyed() {
  //   window.removeEventListener('scroll', this.handleScroll);
  // },
  components: {
  },
  methods: {
    async getInfoUser() {
      console.log("consultado informacion del usuario en experiencias: "+this.idUser);
      const res = await authenticationService.getInfoUser(this.idUser);
      this.infoUser = {
        firstName: res.data.details.firstName,
        lastName: res.data.details.lastName,
        country: res.data.details.country,
        biography: res.data.details.biography,
        photo: res.data.details.profilePhoto.larger
      }
    },
    goTravels() {
      console.log('go travel');
      if (this.idUser == null || this.idUser == '') {
        this.$router.push('/travels');
      } else {
        this.$router.push('/user/'+this.idUser+'/travels');
      }
    },
    goExperiences() {
      console.log('go experiences');
      if (this.idUser == null || this.idUser == '') {
        this.$router.push('/experiences');
      } else {
        router.push('/user/'+this.idUser+'/experiences');
      }
    },
    goMaps() {
      console.log('go maps');
      if (this.idUser == null || this.idUser == '') {
        this.$router.push('/maps');
      } else {
        router.push('/user/'+this.idUser+'/maps');
      }
    },
    goAddExperience() {
      console.log('agregando experiencia::');
      this.$router.push('/experiences/create');
    },
    cancel() {
      this.viewExperiences = true;
    },
    handleScroll() {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 10) {
        // console.log("llegamos al final!!");
        if (this.puedeBuscarMas) {
          console.log("buscando mas!!!");
          const mediaQuery = window.matchMedia('(max-width: 900px)')
          if (mediaQuery.matches) {
            this.pagina++;
            this.cargandoDatos = true;
          }

        }
        //
      }
    },
    //logout: authenticationService.logout,
    nuevaExperiencia() {
      console.log("creando experiencia");
      router.push('/nueva/experiencia');
    },
    deleteExperience(id) {
      Swal.fire({
        title: '¿Desea eliminar la experiencia seleccionada?',
        text: "",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, continuar'
      }).then((result) => {
        if (result.value) {
          // show deleting spinner
          this.experiences = this.experiences.map(x => {
            if (x.id === id) x.isDeleting = true;
            return x;
          });

          // delete account
          experienceService.delete(id)
            .then(() => {
              this.experiences = this.experiences.filter(x => x.id !== id)
              this.experiencesMovil = this.experiencesMovil.filter(x => x.id !== id)
            });
          Swal.fire(
            'Exito!',
            'Se ha eliminado la experiencia',
            'success'
          )
        } else {
          console.log("cancelo");
        }
      })

    }
  },
  watch: {
    viewToShow() {
      if (this.viewToShow == 'TRAVELS') {
        this.goTravels()
      } else if (this.viewToShow == 'EXPERIENCES') {
        this.goExperiences()
      } else if (this.viewToShow == 'MAPS') {
        this.goMaps()
      }
    },
    pagina: function () {
      this.experiences = null;
      this.puedeBuscarMas = false;
      experienceService.getAll(this.pagina - 1)
        .then(x => {
          this.experiences = x.data;
          for (var valor of x.data) {
            this.experiencesMovil.push(valor);
          }
          this.pageInfo = x.pageInfo;
          this.pagina = x.pageInfo.number + 1;
          console.log(this.pagina);
          if (this.pagina < x.pageInfo.totalPages) {
            this.puedeBuscarMas = true;
          }
          this.cargandoDatos = false;
        });

    }
  }
}
</script>
<style>
.contenedor-movil {
  display: none;
}

@media screen and (max-width: 900px) {
  .contenedor-movil {
    display: block;
  }

  .contenedor-web {
    display: none;
  }
}

.pading-boot {
  padding-bottom: 10px;
}

.rosaletztrav {
  background-color: #f19dc0;
  border-color: #f19dc0;
}

.azulletztrav {
  background-color: #003686;
  border-color: #003686;
}

.pagination {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.vistamovil {
  display: none;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}

.vistaweb {
  padding-top: 40px;
}



@media screen and (max-width: 900px) {

  .vistaweb {
    display: none;
  }

  .vistamovil {
    display: flex;
  }

}

.aling-r {
  text-align: right;
}

.aling-l {
  text-align: left;
}

.button-active {
  height: 40px;
  width: 190px;
  margin: 0 5px;
  background-color: #0aaebd;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 8px;
}

.simple-button-letz {
  height: 40px;
  width: 190px;
  margin: 0 5px;
  background-color: #a8b1b7;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.5s;
  border-radius: 8px;
}

.simple-button-letz:hover {
  background-color: #0aaebd;
}

.buttonsContent {
  padding-top: 20px;
  margin-bottom: 35px;
}

#description-container {
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #fff;
}

.description {
  max-width: 200px;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #fff;
}

.dataUser {
  margin-left: 20px;
  padding-top: 170px;
}

.dataUser2 {
  margin-left: 20px;
}

.userStyle {
  color: #0032a0;
  font-weight: 600;
}

.img-foto {
  padding-top: 155px;
  width: 80%;
}

.button-travel-add {
  height: 40px;
  width: 190px;
  margin: 0 5px;
  background-color: #0032a0;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  margin-top: 40px;
}

.bajarcomponente {
  margin-top: 40px;
}
</style>