<template>
	<div>
		<div class="container">
			<div class="row">
				<div class="d-none d-lg-block col-lg-6">
					<div class="img-logo container-login2">
						<img src="https://letzgeneral-public.s3.us-east-1.amazonaws.com/webletztrav/LetZTrav_nbg-010.png">
					</div>
				</div>
				<div class="col-12 col-lg-6">
					<div class="login-content container-login2">
						<div class="formm">
							<b>Comparte la experiencia de viajar</b>
							<br>
							<b class="title">Registro</b>
							<hr size="10px" color="black" />
							<div class="input-divf">
								<input class="form-control textForm2" v-model="firstName" type="text" placeholder="Nombre">
							</div>
							<div class="input-divf">
								<input class="form-control textForm2" v-model="lastName" type="text"
									placeholder="Apellido paterno">
							</div>
							<div class="input-divf text-left">
								<label class="labelBirdate" for="idbirthdate">Fecha de cumpleaños</label>
								<input class="form-control textForm2" id="idbirthdate" v-model="birthdate" type="date"
									placeholder="Fecha de cumpleaños">
							</div>
							<div class="input-divf text-left">
								<select v-model="gender" id="idGener" class="form-control textForm2">
									<option value="">Género</option>
									<option value="FEMALE">Femenino</option>
									<option value="MALE">Masculino</option>
									<option value="OTHER">Otro</option>
								</select>
							</div>
							<div class="input-divf">
								<input class="form-control textForm2" v-model="email" type="text"
									placeholder="Correo electrónico">
							</div>
							<div class="input-divf">
								<input class="form-control textForm2" v-model="password" type="password" name="password" id="password"
									placeholder="Contraseña">
									<i @click="showPassword" class="bi bi-eye-slash ojito" id="togglePassword"></i>
							</div>
							<div class="input-divf">
								<input class="form-control textForm2" v-model="confirmPassword" type="password" id="password2"
									placeholder="Confirma contraseña">
							</div>
							<a class="txtCrear"
								href="https://letzgeneral-public.s3.us-east-1.amazonaws.com/CondicionesDeServicioDeLetZTrav.pdf"
								target="_blank">Al registrarte aceptas los <strong class="strongclass">terminos y
									condiciones</strong> y reconoces que leíste nuestra <strong class="strongclass">política
									de privacidad</strong></a>
							<button @click="registerCount" class="btnSiguiente">
								Crear
							</button>
							<div>
								<hr class="hrLinea"> ó
								<hr class="hrLinea">
							</div>
							<button class="btn btn-facebook btnLoginFacebook" @click="registerFB">
								<i class="fa fa-facebook mr-1"></i>
								Register with Facebook
							</button>
							<hr size="10px" color="black" />
							<a class="txt" href="#">¿Ya tienes una cuenta?</a>
							<a class="txtCrear" @click="goToLogin">Ir a Login</a>
						</div>

						<div id="fb-root"></div>

					</div>
				</div>
			</div>
		</div>

	</div>
</template>
<script scoped>
import { router } from '@/_helpers';
import { authenticationService, userService } from '@/_services';

export default {
	data() {
		return {
			modeLogin: true,
			lastName: '',
			firstName: '',
			deviceId: '',
			motherLastName: '',
			birthdate: '',
			appOS: 'Web',
			location: '',
			password: '',
			city: '',
			biography: '',
			country: 'Mexico',
			gender: '',
			email: '',
			confirmPassword: '',
			confirmAccoun: false,
			codeActivation: ''
		}
	},
	created() {
		// redirect to home if already logged in
		if (authenticationService.accountValue) {
			console.log("redireccionandooo")
			router.push('/travels');
		}
	},
	components: {
	},
	methods: {
		showPassword() {
			const passwordElement = document.querySelector('#password');
			const password2Element = document.querySelector('#password2');
			const type = passwordElement.getAttribute('type') === 'password' ? 'text' : 'password';
			passwordElement.setAttribute('type', type);
			password2Element.setAttribute('type', type);
			const iElement = document.querySelector('#togglePassword');
			if (type == 'text') {
				iElement.classList.remove("bi-eye-slash");
				iElement.classList.add("bi-eye");
			} else {
				iElement.classList.remove("bi-eye");
				iElement.classList.add("bi-eye-slash");
			}
		},
		validateForm() {
			if (!this.firstName || !this.lastName || !this.birthdate || !this.gender || !this.email || !this.password || !this.confirmPassword) {
				alert("Todos los campos son obligatorios");
				return false;
			}

			const passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[_@#$%^&+=./}])(?=\S+$).{8,30}$/;

			if (!passwordRegex.test(this.password)) {
				alert("La contraseña no cumple con los requisitos especificados.");
				return false;
			}

			if (this.password !== this.confirmPassword) {
				alert("Las contraseñas no coinciden.");
				return false;
			}

			return true;
		},

		goToLogin() {
			router.push('/login');
		},
		clean() {
			this.lastName = '';
			this.firstName = '';
			this.deviceId = '';
			this.motherLastName = '';
			this.birthdate = '';
			this.location = '';
			this.password = '';
			this.city = '';
			this.biography = '';
			this.country = '';
			this.gender = '';
			this.email = '';
		},
		async registerCount() {

			const params = {
				lastName: this.lastName,
				firstName: this.firstName,
				deviceId: this.deviceId,
				motherLastName: this.motherLastName,
				birthdate: new Date(this.birthdate).toISOString(),
				appOS: this.appOS,
				location: this.location,
				password: this.password,
				city: this.city,
				biography: this.biography,
				country: this.country,
				gender: this.gender,
				email: this.email,
			}
			console.log('register ccount:');
			console.log(params);
			try {
				if (this.validateForm()) {
					const res = await authenticationService.register(params);
					console.log(res);
					let emailb64 = btoa(this.email);
					this.clean();
					router.push('/account/confirm/'+emailb64);
				}
			} catch (error) {
				error.log(error)
			}

		},
		async activeAcount() {
			try {
				console.log(this.codeActivation);
				console.log(this.email);
				const res = await userService.activeUser(this.codeActivation, this.email);
				console.log(res);
				router.push('/login');
				//reutrn login
			} catch (error) {
				error.log(error)
			}

		},
		forgotPass() {
			console.log('olvido pass:');
		},
		iniciarsesion() {
			console.log("iniciando sesion con: " + this.email + " - " + this.password)
			this.loginUsrPwd(this.email, this.password)
			/*categoryService.create(request).then(() => {
				console.log("regresanddoooooo");
				Swal.fire(
		  'Se envio la solicitud',
		  'En un momento nos comunicamos con usted para dar seguimiento a su solcitud',
		  'success'
			);
				router.push('../');
			})
			.catch(error => {
				this.error = error;
				this.loading = false;
			});*/
		},
		registerFB: authenticationService.registerFB,
		loginUsrPwd: authenticationService.loginUP
	}
}
</script>
<style>
.strongclass {
	font-weight: bold;
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

body {
	font-family: 'Poppins', sans-serif;
	overflow: auto;
}

.labelBirdate {
	font-size: 16px;
	color: #6C757D;
}

/*.container-login{
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap :7rem;
    padding: 0 2rem;
}*/

.img {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.img img {
	width: 500px;
}

.formm {
	width: 360px;
}

.login-content img {
	height: 250px;
}

.btnFacebook img {
	height: 30px;
}

.login-content h2 {
	margin: 15px 0;
	color: #333;
	text-transform: uppercase;
	font-size: 2.9rem;
}

.login-content .input-div {
	position: relative;
	display: grid;
	grid-template-columns: 7% 93%;
	margin: 25px 0;
	padding: 5px 0;
	border-bottom: 2px solid #d9d9d9;
}

.login-content .input-div.one {
	margin-top: 0;
}

.i {
	color: #d9d9d9;
	display: flex;
	justify-content: center;
	align-items: center;
}

.i i {
	transition: .3s;
}

.input-div>div {
	position: relative;
	height: 45px;
}

.input-div>div>h5 {
	position: absolute;
	left: 10px;
	top: 50%;
	transform: translateY(-50%);
	color: #999;
	font-size: 18px;
	transition: .3s;
}

.input-div:before,
.input-div:after {
	content: '';
	position: absolute;
	bottom: -2px;
	width: 0%;
	height: 2px;
	background-color: #38d39f;
	transition: .4s;
}

.input-div:before {
	right: 50%;
}

.input-div:after {
	left: 50%;
}

.input-div.focus:before,
.input-div.focus:after {
	width: 50%;
}

.input-div.focus>div>h5 {
	top: -5px;
	font-size: 15px;
}

.input-div.focus>.i>i {
	color: #38d39f;
}

.input-div>div>input {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border: none;
	outline: none;
	background: none;
	padding: 0.5rem 0.7rem;
	font-size: 1.2rem;
	color: #555;
	font-family: 'poppins', sans-serif;
}

.input-div.pass {
	margin-bottom: 4px;
}

a {
	display: block;
	text-align: right;
	text-decoration: none;
	color: #999;
	font-size: 0.9rem;
	transition: .3s;
}

a:hover {
	color: #38d39f;
}

a {
	cursor: pointer
}

.btn-facebook {
	background: #3B5998;
	color: #fff;
}

.btn-facebook:hover {
	color: #fff;
	opacity: 0.8;
}

.btn-delete-account {
	width: 40px;
	text-align: center;
	box-sizing: content-box;
}

.btn-new-experience {
	width: 90px;
	text-align: center;
	box-sizing: content-box;
}

/*.btn{
	display: block;
	width: 100%;
	height: 50px;
	border-radius: 25px;
	outline: none;
	border: none;
	background-image: linear-gradient(to right, #32be8f, #38d39f, #32be8f);
	background-size: 200%;
	font-size: 1.2rem;
	color: #fff;
	font-family: 'Poppins', sans-serif;
	text-transform: uppercase;
	margin: 1rem 0;
	cursor: pointer;
	transition: .5s;
}
.btn:hover{
	background-position: right;
}*/

.btnFacebook {
	display: block;
	width: 100%;
	height: 50px;
	border-radius: 25px;
	outline: none;
	border: none;
	background-size: 200%;
	font-size: 1.2rem;
	color: #fff;
	font-family: 'Poppins', sans-serif;
	text-transform: uppercase;
	margin: 1rem 0;
	cursor: pointer;
	transition: .5s;
}

.btnFacebook:hover {
	background-position: right;
}

@media screen and (max-width: 1050px) {
	.container {
		grid-gap: 5rem;
	}
}

@media screen and (max-width: 1000px) {
	.formm {
		width: 290px;
	}

	.login-content h2 {
		font-size: 2.4rem;
		margin: 8px 0;
	}

	.img img {
		width: 400px;
	}
}

@media screen and (max-width: 900px) {
	.container {
		grid-template-columns: 1fr;
	}

	.img {
		display: none;
	}

	.wave {
		display: none;
	}

	.login-content {
		justify-content: center;
	}
}

.btnLoginFacebook {
	text-align: center;
	display: block;
	width: 100%;
	height: 35px;
	border-radius: 10px;
	outline: none;
	border: none;
	background-image: linear-gradient(to right, #3b5998, #3b5998, #4466af);
	background-size: 200%;
	font-size: 1rem;
	color: #fff;
	font-family: 'Poppins', sans-serif;
	margin: 1rem 0;
	cursor: pointer;
	transition: .5s;
}

.btnLoginFacebook:hover {
	background-position: right;
}

</style>