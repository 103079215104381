<template>
    <div class="addTravelBody">
        <hr>
        <h3><b> Agregar experiencia </b></h3>
        <br>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <input id="autocomplete" placeholder="Lugar" v-model="to" type="text" ref="inputSearchRef"
                        class="inputPlaces w400">
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <select class="inputPlaces w400 cp mt-3" v-model="selectedCategory">
                        <option disabled value="">Categoría</option>
                        <option v-for="option in categories" :key="option.id" v-bind:value="option.id">
                            {{ option.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="contentvselect inputPlaces w400 cp mt-3">
                        <v-select v-model="modelExperience" placeholder="Experiencia" taggable label="title"
                            :options="options">
                            <span slot="no-options">Selecciona una experiencia, o escribe la tuya!</span>
                        </v-select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <input placeholder="Provedor de servicios" v-model="providers" type="text"
                        class="inputPlaces w400 mt-3">
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="">
                        <label for=""><b>Fecha</b></label>
                        &nbsp;
                        <select class="inputPlaces w50 cp mt-3" v-model="daySelected">
                            <option disabled value="">Día</option>
                            <option v-for="day in days" :key="day" :value="day">{{ day }}</option>
                        </select>

                        &nbsp;
                        <select class="inputPlaces w150 cp mt-3" v-model="selectedMonth">
                            <option disabled value="">Mes</option>
                            <option v-for="month in months" :key="month.value" :value="month.value">{{ month.month }}
                            </option>
                        </select>
                        &nbsp;
                        <input placeholder="Año" v-model="year" type="text" class="inputPlaces w50" maxlength="4">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 mt-4">
                    <label v-if="!withImages" @click="addPhotos" class="labelAddFotos">+ Agregar fotos</label>
                    <UploadImage v-if="withImages" server="https://api-dev.letztrav.com/api/file/temporal" maximo=15
                        @imagenes="actualizarImagenes">
                    </UploadImage>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button v-if="isDisabled" @click="save" class="button-travel-add-confirm-disabled">
                        Agregar
                    </button>
                    <button v-if="!isDisabled" @click="save" class="button-travel-add-confirm">
                        <span v-if="!guardando">Agregar</span>
                        <span v-else class="spinner-border spinner-border-lg align-center"></span>
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h2 class="cancelBtn" @click="cancel">Cancelar</h2>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import 'vue-select/dist/vue-select.css';
import UploadImage from '@/components/utils/UploadImage'
//import SelectOptions from '@/components/utils/SelectOptions'
import { categoryService, catalogExperienceService, experienceService } from '@/_services';
export default {
    data() {
        return {
            guardando: false,
            options: [
                /*{
                    id: "64d32bdb7a2145230c533234",
                    title: "Nueva experiencia con imagenes user feliciano test",
                    category: "63523311624ad880c498e51d",
                    serviceProvider: "Service provider"
                },
                {
                    id: "65289b7a3b1a0a7aa243063e",
                    title: "Nueva experiencia con mas datos 3",
                    category: "63523311624ad880c498e51d",
                    serviceProvider: "Cliente uno de prueba"
                }*/
            ],
            formExperience: {
                id: null,
                name: ''
            },

            from: '',
            fromDate: '',
            isDisabled: true,
            fromLocation: {
                latitude: null,
                longitude: null
            },
            toDate: '',
            to: '',
            toLocation: {},
            urlImagenes: [],
            withImages: false,
            providers: '',
            selectedCategory: '',
            publicTrip: true,
            categories: [],
            catalogExperiences: [],
            experience: '',
            modelExperience: {
                id: null,
                title: null,
                category: null,
                serviceProvider: null
            },
            place: '',
            isValidYear: false,
            daySelected: '',
            days: [...Array(31).keys()].map(number => number + 1),
            month: '',
            year: '',
            // year: new Date().getFullYear(),
            selectedMonth: '',
            months: [
                { month: 'Enero', value: 1 },
                { month: 'Febrero', value: 2 },
                { month: 'Marzo', value: 3 },
                { month: 'Abril', value: 4 },
                { month: 'Mayo', value: 5 },
                { month: 'Junio', value: 6 },
                { month: 'Julio', value: 7 },
                { month: 'Agosto', value: 8 },
                { month: 'Septiembre', value: 9 },
                { month: 'Octubre', value: 10 },
                { month: 'Noviembre', value: 11 },
                { month: 'Diciembre', value: 12 },
            ],
            sugerencias: [],
        }
    },
    components: {
        UploadImage,
        //SelectOptions
    },
    methods: {
        initAutocomplete() {
            var autocomplete;
            // eslint-disable-next-line no-undef
            autocomplete = new google.maps.places.Autocomplete(this.$refs.inputSearchRef,
                {
                    types: ['locality', 'political'],
                    // componentRestrictions: { 'country': ['es'] }, 
                    fields: ['place_id', 'geometry', 'name', 'address_components', 'formatted_address', 'adr_address'],
                });
            autocomplete.addListener('place_changed', () => {
                var dataCitySelected = autocomplete.getPlace();
                console.log('dataCitySelected::');
                console.log(dataCitySelected);
                this.to = dataCitySelected.formatted_address;
                this.toLocation = {
                    latitude: dataCitySelected.geometry.location.lat(),
                    longitude: dataCitySelected.geometry.location.lng()
                };
            });
        },
        verifyDay() {
            if (this.days.includes(this.daySelected)) {
                console.log(`El número ${this.daySelected} está en el array.`);
            } else {
                console.log(`El número ${this.daySelected} no está en el array.`);
                this.daySelected = '';
            }
        },
        verifyMonthAndYear() {
            if (this.selectedMonth == '' || this.year == '') {
                return false;
            } else {
                return true;
            }
        },
        verifyDate() {
            if (this.selectedMonth == '' || this.year == '' || this.daySelected == '') {
                this.isDisabled = true;
                return false;
            } else {
                console.log('FECHA COMPLAETA:::');
                const month = this.setTimeFormat(this.selectedMonth);
                const day = this.setTimeFormat(this.daySelected);
                this.toDate = `${this.year}-${month}-${day}`
                console.log('date::');
                console.log(this.toDate);
                // const formatDate = new Date(date);
                // this.toDate = formatDate.toISOString();
                this.verifyInputs();
                return true;
            }
        },

        verifyInputs() {
            if (this.to != '' || this.selectedCategory != '') {
                this.isDisabled = false;
            } else {
                this.isDisabled = true;
            }
        },
        validateYear() {

            const yearPattern = /^\d{4}$/;
            if (yearPattern.test(this.year)) {
                this.isValidYear = true;
                console.log('año valido');
                console.log(this.year);
                if (this.verifyMonthAndYear()) {
                    this.days = this.obtenerDiasDelMes(this.selectedMonth, this.year);
                    this.verifyDay();
                    this.verifyDate();
                }
            } else {
                this.isValidYear = false;
                this.isDisabled = true;
                console.log('año no valido');
                console.log(this.year);
            }
        },
        obtenerDiasDelMes(mes, año) {
            mes = mes - 1;
            const ultimaFechaDelMes = new Date(año, mes + 1, 0);
            const numeroDeDias = ultimaFechaDelMes.getDate();
            const listaDeDias = [];
            for (let dia = 1; dia <= numeroDeDias; dia++) {
                listaDeDias.push(dia);
            }

            return listaDeDias;
        },

        setTimeFormat(time) {
            return time < 10
                ? '0' + time
                : time
        },
        addPhotos() {
            this.withImages = true
            console.log('agregando fotos::');
        },

        cancel() {
            this.$router.push('/experiences');

        },
        actualizarImagenes(urls) {
            this.urlImagenes = urls;
            console.log("arreglo de imagenes");
            console.log(this.urlImagenes);
        },

        async save() {
            this.guardando = true;
            var params
            console.log("this.modelExperience");
            console.log(this.modelExperience);
            console.log("this.modelExperience");
            if (this.modelExperience.id != null) {
                params = {
                    reservedDate: this.toDate,
                    experience: this.modelExperience.id,
                    newImages: this.urlImagenes
                }
            } else {
                params = {
                    nameExperience: this.modelExperience,
                    direction: this.to,
                    shortDirection: this.to,
                    categoryId: this.selectedCategory,
                    reservedDate: this.toDate,
                    newImages: this.urlImagenes,
                    serviceProvider: this.providers
                }
            }
            try {
                await experienceService.create(params);
                this.to = '';
                this.selectedCategory = '';
                this.experience = '';
                this.providers = {};
                this.daySelected = '';
                this.selectedMonth = '';
                this.year = '';
                this.guardando = false;
                this.$router.push('/experiences');
            } catch (error) {
                this.guardando = false;
                error.log(error);
            }
        },
    },
    created() {
        categoryService.getAll().then(x => this.categories = x);
    },


    mounted() {
        this.initAutocomplete();
    },

    watch: {
        modelExperience() {
            this.providers = this.modelExperience.serviceProvider
        },
        year() {
            this.validateYear();
        },
        daySelected() {
            console.log('dia::');
            console.log(this.daySelected);
            this.verifyDate();
        },
        selectedMonth() {
            console.log('mes::');
            console.log(this.selectedMonth);
            if (this.verifyMonthAndYear()) {
                this.days = this.obtenerDiasDelMes(this.selectedMonth, this.year);
                this.verifyDay();
                this.verifyDate()
            }
        },
        to() {
            this.verifyDate()
        },

        selectedCategory() {
            console.log("cambiando de categoria: " + this.selectedCategory);
            this.catalogExperiences = []
            catalogExperienceService.findByCategoryAndLocation(this.selectedCategory, this.toLocation.longitude, this.toLocation.latitude, 0).then(x => {
                this.catalogExperiences = x;
                /*if(x.length>0) {
                    this.providers = x[0].serviceProvider
                }*/


                console.log('x::::');
                console.log(x);
                console.log("llenando lista de opciones")
                this.options = []
                this.catalogExperiences.forEach(experiences => {
                    this.options.push({
                        id: experiences.id,
                        title: experiences.name,
                        category: experiences.category,
                        serviceProvider: experiences.serviceProvider
                    })
                });
                console.log(this.options)
                console.log("this.options")
            });
        },
        experience() {
            console.log('experience');
            console.log(this.experience);
        }

    }

}
</script>

<style>
hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid #a8b1b7;
}

.cancelBtn {
    cursor: pointer;
    font-size: 14px;
}

.inputPlaces {
    border: solid 1px red;
    border-radius: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 10px;
    padding-right: 10px;
}

.w400 {
    width: 400px;
}

.w100 {
    width: 100px;
}

.w150 {
    width: 150px;
}

.w200 {
    width: 200px;
}

.w50 {
    width: 80px;
}

.cp {
    padding-top: 10px;
    padding-bottom: 10px;
}

.labelAddFotos {
    text-decoration: underline;
    cursor: pointer;
}

.contentvselect {
    margin: 0 auto;
}
</style>