import { BehaviorSubject } from 'rxjs';
import axios from 'axios';

const baseUrl = `${process.env.VUE_APP_API_URL}/api/core/friendship`;
const friendshipSubject = new BehaviorSubject(null);


export const friendshipService = {
    searchFriend,
    addFriend,
    patchRequestFriend,
    friendship: friendshipSubject.asObservable(),
    get friendshipValue () { return friendshipSubject.value; }
};

function searchFriend(pagina, itemsPerPage, fullname) {
    console.log("buscando amigos");
    console.log(pagina);
    console.log(itemsPerPage);
    return axios.get(`${baseUrl}/searches/${pagina}/${itemsPerPage}?fullname=${fullname}`, {withCredentials: true})
        .then(response => response.data);
}

async function addFriend(friendId) {
    var params = {"friendId": friendId}
    const response = await axios.post(`${baseUrl}`, params , {withCredentials: true});
    let responseData = response.data;
    return responseData;
}

async function patchRequestFriend(friendId, isAccepted) {
    var params = {"friendId": friendId, "accept": isAccepted}
    const response = await axios.put(`${baseUrl}`, params , {withCredentials: true});
    let responseData = response.data;
    return responseData;
}
