<template>
    <div class="separar justify-content-center d-flex align-items-center">
        <div class="card py-4">
            <div class="text-center">
                <Bar urlDatos="/api/statistics/dots" ></Bar>
            </div>
        </div>
    </div>
</template>
<script>
    import Bar from '@/components/utils/charts/Bar'
    export default {
        props:['experience'],
        methods: {
        },
        components: {
            Bar
        },
    }
    
</script>
<style>
    .separar{
        margin-top: 10px;
        margin-bottom: 10px;
    }

.card {
    width: 99%;
    padding: 15px;
    border: none;
    cursor: pointer;
    transition: all 0.5s
}

.content {
    font-size: 13px
}

.follow {
    border-radius: 25px;
    padding-right: 19px;
    padding-left: 19px;
    height: 42px;
    font-size: 19px;
    width: 130px;
    margin: 1px;
}

.rosaletztrav{
    background-color: #f19dc0;
    border-color: #f19dc0;
}

.azulletztrav{
    background-color: #003686;
    border-color: #003686;
}


.card:hover {
    box-shadow: 0 0 40px rgba(51, 51, 51, .1)
}
</style>