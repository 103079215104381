import { BehaviorSubject } from 'rxjs';
import axios from 'axios';

// const baseUrl = `${process.env.VUE_APP_API_URL}/api/experiences/past/6271e80409273c79e509849b`;
const baseUrl = `${process.env.VUE_APP_API_URL}/api/experiences/past`;
const baseUrl2 = `${process.env.VUE_APP_API_URL}`;
// experiences/past/0/15
// const baseUrlExperiences = `${process.env.VUE_APP_API_URL}/api/statistics/experience`;
const experienceSubject = new BehaviorSubject(null);


export const experienceService = {
    getPastExperiences,
    getExperience,
    create,
    getById,
    getStadistics,
    update,
    addPhotos,
    removePhotos,
    delete: _delete,
    experience: experienceSubject.asObservable(),
    get experienceValue () { return experienceSubject.value; }
};

function getPastExperiences(pagina, itemsPerPage, idUser) {
    console.log("buscando experiencias");
    console.log(pagina);
    console.log(itemsPerPage);
    if (idUser== null || idUser == '') {
        return axios.get(`${baseUrl}/`+pagina+`/`+itemsPerPage+``, {withCredentials: true})
        .then(response => response.data);
    } else {
        return axios.get(`${baseUrl}/`+idUser+`/`+pagina+`/`+itemsPerPage+``, {withCredentials: true})
        .then(response => response.data);
    }        
}

function getExperience(id) {
    console.log("buscando experiencias");
    return axios.get(`${baseUrl2}/api/experiences/`+id+``, {withCredentials: true})
        .then(response => response.data);
}

async function create(params) {
    const response = await axios.post(`${baseUrl}`, params , {withCredentials: true});
    let responseData = response.data;
    return responseData;
}

function getById(id) {
    console.log("buscando experiencia: "+id);
    return axios.get(`${baseUrl}/${id}`, {withCredentials: true})
        .then(response => response.data);
}

function getStadistics(url) {
    return axios.get(`${process.env.VUE_APP_API_URL}`+url, {withCredentials: true})
        .then(response => response);
}

async function update(params) {
    const response = await axios.put(`${baseUrl}`, params, {withCredentials: true});
    let experience = response.data;
    // update the current account if it was updated
    /*if (experience.id === experienceSubject.value?.id) {
        // publish updated account to subscribers
        experience = { ...experienceSubject.value, ...experience };
        experienceSubject.next(experience);
    }*/
    return experience;
}

async function addPhotos(travelId, params) {
    const response = await axios.patch(`${baseUrl2}/api/experiences/add/photo/`+travelId, params, {withCredentials: true});
    let experience = response.data;
    return experience;
}

async function removePhotos(travelId, params) {
    const response = await axios.patch(`${baseUrl2}/api/experiences/remove/photo/`+travelId, params, {withCredentials: true});
    let experience = response.data;
    return experience;
}

async function _delete(id) {
    await axios.delete(`${baseUrl}/${id}`, {withCredentials: true});
    /*if (id === experienceSubject.value?.id) {
        // auto logout if the logged in account was deleted
        logout();
    }*/
}