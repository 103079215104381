<template>
  <nav v-if="showNavbar" class="navbar fixed-top navbar-expand-lg bg-custom">
    <img src="@/assets/images/logo-icon.png" width="30" height="30" class="d-inline-block align-top rounded-circle" alt=""
      @click="goToHome">
    <div class="form-inline my-2 my-lg-0 searchSecundary">
      <input v-model="searchQuery" @input="handleInput" class="custom-search mr-sm-2" type="search" placeholder="Buscar"
        aria-label="Search">
      <div v-if="buscando || searchResults.length > 0" class="result-container">
        <ul class="cajabusqueda">
          <li><span v-if="buscando" class="spinner-border spinner-border-lg align-center"></span></li>
          <li class="itembusqueda" v-for="user in searchResults" :key="user.id">
            <img :src="user.profilePhoto.thumbnail" alt="" class="notification-image"> {{ user.firstName + " " +
              user.lastName + " " + user.motherLastName }} <button @click="viewUser(user.userId)" v-if="user.friendship"
              class="button-letztrav-blue-small"> Ver </button>
            <button @click="addFriend(user.userId)" v-else class="button-letztrav-auto-nuevo">Conectar</button>
          </li>
        </ul>
      </div>
    </div>

    <button class="navbar-toggler navbar-light" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="customUser">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-menu-app"
          viewBox="0 0 16 16">
          <path
            d="M0 1.5A1.5 1.5 0 0 1 1.5 0h2A1.5 1.5 0 0 1 5 1.5v2A1.5 1.5 0 0 1 3.5 5h-2A1.5 1.5 0 0 1 0 3.5v-2zM1.5 1a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-2zM0 8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8zm1 3v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2H1zm14-1V8a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2h14zM2 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z" />
        </svg>
      </span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto searchPrincipal">
        <li class="searchInput nav-item active">
          <div class="search-container">
            <div class="form-inline my-2 my-lg-0">
              <input v-model="searchQuery" @input="handleInput" class="custom-search mr-sm-2" type="search"
                placeholder="Buscar" aria-label="Search">
              <div v-if="buscando || searchResults.length > 0" class="result-container">
                <ul class="cajabusqueda">
                  <li><span v-if="buscando" class="spinner-border spinner-border-lg align-center"></span></li>
                  <li class="itembusqueda" v-for="user in searchResults" :key="user.id">
                    <img :src="user.profilePhoto.thumbnail" alt="" class="notification-image"> {{ user.firstName + " " +
                        user.lastName + " " + user.motherLastName }} <button @click="viewUser(user.userId)"
                      v-if="user.friendship" class="button-letztrav-blue-small"> Ver </button>
                    <button @click="addFriend(user.userId)" v-else class="button-letztrav-auto-nuevo">Conectar</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="form-inline my-2 my-lg-0">
        <li class="nav-item dropdown" style="padding-right: 10px;">
          <!--<li @click="buscarNofiticaciones()" class="nav-item dropdown" style="padding-right: 10px;"></li>-->
          <span class="navbar-brand" data-toggle="dropdown">
            <svg fill="#fff" height="30px" width="30px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 300 300" xml:space="preserve">
              <g id="SVGRepo_bgCarrier" stroke-width="0" />
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
              <g id="SVGRepo_iconCarrier">
                <g>
                  <g>
                    <path
                      d="M149.996,0C67.157,0,0.001,67.161,0.001,149.997S67.157,300,149.996,300s150.003-67.163,150.003-150.003 S232.835,0,149.996,0z M149.999,232.951c-10.766,0-19.499-8.725-19.499-19.499h38.995 C169.497,224.226,160.765,232.951,149.999,232.951z M215.889,193.9h-0.005v-0.001c0,7.21-5.843,7.685-13.048,7.685H97.16 c-7.208,0-13.046-0.475-13.046-7.685v-1.242c0-5.185,3.045-9.625,7.42-11.731l4.142-35.753c0-26.174,18.51-48.02,43.152-53.174 v-13.88c0-6.17,5.003-11.173,11.176-11.173c6.17,0,11.173,5.003,11.173,11.173V92c24.642,5.153,43.152,26.997,43.152,53.174 l4.142,35.758c4.375,2.109,7.418,6.541,7.418,11.726V193.9z" />
                  </g>
                </g>
              </g>
            </svg>
            <div v-if="newNotifications" class="blue-circle"></div>
          </span>
          <div class="dropdown-menu">
            <a v-if="buscandoNotificaciones" class="dropdown-item" @click="settings()">
              <span class="spinner-border spinner-border-lg align-center"></span>
            </a>
            <div v-for="notification in notifications" :key="notification.id" class="notification-container">
              <div v-if="!notification.read" class="green-circle"></div>
              <img :src="notification.urlPhoto" alt="" class="notification-image">
              <div>
                <a class="dropdown-item" @click="viewUserNotification(notification)">
                  <li><strong>{{ notification.title }}</strong></li>
                  <li>{{ notification.content }}</li>
                  <div v-if="notification.type == 'FRIENDSHIP_REQUESTED' && !notification.read">
                    <button @click="patchRequestFriend(notification.sourceUser, false, notification.id)" class="button-letztrav-pink-small">Cancelar</button>
                    <button @click="patchRequestFriend(notification.sourceUser, true, notification.id)" class="button-letztrav-blue-small">Aceptar</button>
                  </div>
                </a>
              </div>
            <div class="dropdown-divider"></div>
    </div>
    </div>
    </li>
    <li class="nav-item dropdown userTab">
      <a class="navbar-brand customUser" href="#" role="button" data-toggle="dropdown" aria-expanded="false">
        {{ infoUser.firstName }} {{ infoUser.lastName }}
        <!--<img src="@/assets/images/logo-icon.png" width="30" height="30" class="d-inline-block align-top rounded-circle" alt="">-->
      </a>
      <div class="dropdown-menu">
        <a class="dropdown-item" @click="settings()">Configuración</a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" @click="logout()">Cerrar sesión</a>
      </div>
    </li>

    </div>
    </div>
  </nav>
</template>

<script>
import { authenticationService } from '@/_services';
import { router } from '@/_helpers';
import { friendshipService, notificationService } from '@/_services';
import Swal from 'sweetalert2';
export default {
  name: 'NavBar',
  data() {
    return {
      infoUser: {},
      showNavbar: false,
      searchQuery: '',
      searchResults: [],
      searchTimer: null,
      buscando: false,
      buscandoNotificaciones: false,
      notifications: [],
      newNotifications: false
    }
  },
  watch: {
    notifications() {
      console.log("actualizando burbuja de notifiacaciones")
      this.newNotifications = false;
      this.notifications.forEach(notificacion => {
        if (!notificacion.read) {
          this.newNotifications = true;
        }
      });
    },
  },
  methods: {
    handleInput() {
      this.buscando = true;
      console.log("handleInput");
      // Cancelar el temporizador existente si hay alguno
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }

      // Establecer un nuevo temporizador
      this.searchTimer = setTimeout(() => {
        // Realizar la búsqueda después de 2 segundos de inactividad
        this.performSearch();
      }, 500);
    },
    performSearch() {
      console.log("Buscando usuarios... ");
      // Aquí deberías realizar la lógica de búsqueda con el valor de this.searchQuery
      // Por ejemplo, podrías hacer una llamada a tu API para obtener resultados de usuarios.

      // Simulación de resultados de búsqueda
      if (this.searchQuery != '') {
        friendshipService.searchFriend(0, 50, this.searchQuery).then(x => {
          console.log("usuarios encontrados");
          console.log(x);
          this.searchResults = [];
          x.data.forEach(usuarioEncontrado => {
            if (!usuarioEncontrado.you) {
              this.searchResults.push(usuarioEncontrado);
            }
          });
          this.buscando = false;
        });
      } else {
        this.searchResults = [];
        this.buscando = false;
      }

      /*this.searchResults = [
        { id: 1, name: 'Usuario 1' },
        { id: 2, name: 'Usuario 2' },
        // ... más resultados
      ];*/
    },
    goToHome() {
      router.push('/travels');
    },
    viewUserNotification(notification) {
      if (!notification.read && notification.type != 'FRIENDSHIP_REQUESTED') {
        console.log("entrando por aquixxx");
        notificationService.checkNotification(notification.id).then(notif => {
          console.log(notif);
          this.newNotifications = false;
          this.notifications.forEach(notificacion => {
            if (notificacion.id == notification.id) {
              notificacion.read = true;
            }
            if (notificacion.read == false) {
              this.newNotifications = true;
            }
          });
        });
      }

      this.viewUser(notification.sourceUser);
    },
    viewUser(userId) {
      console.log("yendo a users travels");
      router.push('/user/' + userId + '/travels');
      this.searchResults = [];
      this.searchQuery = '';
    },
    async getInfoUser() {
      console.log("obteniendo informacion del usuarioooooxxxxx");
      const res = await authenticationService.getInfoUser();
      this.infoUser = {
        firstName: res.data.details.firstName ?? '',
        lastName: res.data.details.lastName ?? '',
        country: res.data.details.country ?? '',
        biography: res.data.details.biography ?? '',
        photo: res.data.details.profilePhoto.larger ?? ''
      }

      if (res.data.details) {
        this.showNavbar = true;
      } else {
        this.showNavbar = false;
      }
      this.buscarNofiticaciones();
    },
    async logout() {
      this.showNavbar = false;
      await authenticationService.logout();
    },
    async settings() {
      console.log('go settings');
      this.$router.push('/settings/profile');
    },
    async buscarNofiticaciones() {
      this.buscandoNotificaciones = true;
      console.log('buscando nofiticacionesxxxxxxxxxxxxxxxxxxxx');
      notificationService.searchNotifications(0, 50).then(x => {
        console.log("notificaciones encontradas");
        console.log(x);
        this.notifications = [];
        x.data.forEach(notificacion => {
          this.notifications.push(notificacion);
        });
        this.buscandoNotificaciones = false;
      });
    },
    async addFriend(userId) {
      console.log("agregando al usuario: " + userId);
      friendshipService.addFriend(userId).then(x => {
        console.log(x);
        console.log(x.data);
        if (x.code == 0) {
          Swal.fire(
            'Solicitud de amistad enviada"',
            '',
            'success'
          );
        } else {
          console.log("Hubo un problema");
          console.log(x.message);
        }
      });
    },
    async patchRequestFriend(userId, isAcepted, notificationId) {
      console.log("aceptando o rechazando solicitud de amistad: " + userId);
      friendshipService.patchRequestFriend(userId, isAcepted).then(x => {
        console.log("aceptadoooooo");
        console.log(x);
        console.log(x.data);
        console.log(notificationId);
        if (x.code == 0) {
          if (isAcepted) {
            Swal.fire(
              'Solicitud de amistad aceptada"',
              '',
              'success'
            );
          }

        } else {
          console.log("Hubo un problema");
          console.log(x.message);
        }
        notificationService.checkNotification(notificationId).then(notif => {
          console.log(notif);
          this.newNotifications = false;
          this.notifications.forEach(notificacion => {
            if (notificacion.id == notificationId) {
              notificacion.read = true;
            }
            if (notificacion.read == false) {
              this.newNotifications = true;
            }
          });
        });
      });
    }

  }

}
</script>

<style>
.userTab {
  list-style: none;
  margin-right: 110px;
}

.searchInput {
  margin-left: 110px;
}

.customUser {
  color: white;
}

.bg-custom {
  background-color: #0032a0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.custom-search {
  height: 20px;
  border-radius: 30px;
  padding: 18px;
  border: none;
  background-color: #2f5ec3;
  color: white;
  width: 280px;
}

.custom-search::placeholder {
  color: #ffff;
  /* Cambia este valor al color deseado */
}

@media (min-width: 991px) {
  .searchSecundary {
    display: none;
  }
}

@media (max-width: 991px) {
  .searchPrincipal {
    display: none;
  }
}

.search-container {
  position: relative;
}

.result-container {
  position: absolute;
  top: 100%;
  /* Coloca la caja de resultados debajo del input */
  left: 0;
  background-color: #fff;
  /* Color de fondo de la caja blanca */
  border: 1px solid #ccc;
  /* Borde de la caja blanca */
  max-height: 200px;
  /* Altura máxima de la caja blanca para permitir el desplazamiento */
  overflow-y: auto;
  /* Agrega barra de desplazamiento vertical si es necesario */
  width: 100%;
  /* Ocupa todo el ancho del contenedor */
}

.cajabusqueda {
  list-style: none;
  /* Elimina los puntos de la lista */
  padding: 0;
  /* Elimina el relleno de la lista */
}

.itembusqueda {
  border-bottom: 1px solid #ccc;
  /* Línea horizontal entre elementos de la lista */
  padding: 5px;
  display: flex;
  align-items: center;
  /* Espaciado interno */
}

.notification-container {
  display: flex;
  align-items: center;
  /* Centrar verticalmente los elementos dentro del contenedor */
}

.notification-image {
  width: 35px;
  /* Tamaño deseado en píxeles */
  height: 35px;
  /* Tamaño deseado en píxeles */
  margin-left: 5px;
  /* Ajusta el margen derecho según tus preferencias */
}

.blue-circle {
  position: absolute;
  top: 5px;
  right: 22px;
  width: 12px;
  /* Ajusta el tamaño según tus necesidades */
  height: 12px;
  background-color: #f19dc0;
  border-radius: 50%;
}

.green-circle {
  top: 0px;
  right: 0px;
  width: 4px;
  /* Ajusta el tamaño según tus necesidades */
  height: 40px;
  background-color: #1ec926;
}
</style>