<template>
  <div class="addTravelBody">
    <hr>
    <h3><b> Agregar Viaje </b></h3>
    <br>
    <input
        id="autocomplete" 
        placeholder="Lugar"
        v-model="to" 
        type="text"
        ref="inputSearchRef" 
        class="inputPlaces w400"
    >

    <div class="">
        <label for=""><b>Fecha</b></label>
        &nbsp;
        <select class="inputPlaces w50 cp mt-3" v-model="daySelected">
            <option disabled value="">Día</option>
            <option v-for="day in days" :key="day" :value="day">{{ day }}</option>
        </select>

        &nbsp;
        <select class="inputPlaces w150 cp mt-3" v-model="selectedMonth">
            <option disabled value="">Mes</option>
            <option v-for="month in months" :key="month.value" :value="month.value">{{ month.month }}</option>
        </select>
        &nbsp;
        <input 
            placeholder="Año"
            v-model="year" 
            type="text" 
            class="inputPlaces w50"
            maxlength="4"
        >
    </div>
    <div class="col-md-12 mt-4">
        <label v-if="!withImages" @click="addPhotos" class="labelAddFotos">+ Agregar fotos</label>
        <UploadImage v-if="withImages"
        server="https://api-dev.letztrav.com/api/file/temporal"
        maximo=15
        @imagenes="actualizarImagenes"
        >
        </UploadImage>
    </div>
    <div class="col-md-12 mt-n4">
        <button v-if="isDisabled" @click="save" class="button-travel-add-confirm-disabled">
            Agregar
        </button>
        <button v-if="!isDisabled" @click="save" class="button-travel-add-confirm">
            <span v-if="!guardando">Agregar</span>
            <span v-else class="spinner-border spinner-border-lg align-center"></span>
        </button>
    </div>
    <div class="col-md-12 mt-4">
        <h2 class="cancelBtn" @click="cancel">Cancelar</h2>
    </div>



  </div>
</template>

<script>
import { travelService } from '@/_services';
import UploadImage from '@/components/utils/UploadImage'
export default {
    data(){
        return {
            guardando: false,
            urlImagenes:[],
            withImages: false,
            isDisabled: true,
            from:'',
            fromDate:'',
            fromLocation: {
                latitude: null, 
                longitude: null
            },
            toDate:'',
            to:'',
            toLocation: {},
            publicTrip: true,
            isValidYear : false,
            daySelected: '',
            days: [...Array(31).keys()].map(number => number + 1), 
            month:'',
            year: '',
            placesService:'',
            // year: new Date().getFullYear(),
            selectedMonth:'',
            months: [
                {month: 'Enero', value: 1},
                {month: 'Febrero', value: 2},
                {month: 'Marzo', value: 3},
                {month: 'Abril', value: 4},
                {month: 'Mayo', value: 5},
                {month: 'Junio', value: 6},
                {month: 'Julio', value: 7},
                {month: 'Agosto', value: 8},
                {month: 'Septiembre', value: 9},
                {month: 'Octubre', value: 10},
                {month: 'Noviembre', value: 11},
                {month: 'Diciembre', value: 12},
                ],
            sugerencias: [],
        }
    },
    components: {
        UploadImage
    },
    mounted(){
        this.initAutocomplete();
    },
    methods: {
        verifyDay(){
            if (this.days.includes(this.daySelected)) {
                console.log(`El número ${this.daySelected} está en el array.`);
            } else {
                console.log(`El número ${this.daySelected} no está en el array.`);
                this.daySelected = '';
            }
        },
        verifyMonthAndYear(){
            if (this.selectedMonth == '' || this.year =='') {
                return false;
            }else {
                return true;
            }
        },
        verifyDate(){
            if (this.selectedMonth == '' || this.year =='' || this.daySelected == '' ) {
                this.isDisabled = true;
                return false;
            }else {
                console.log('FECHA COMPLAETA:::');
                const month = this.setTimeFormat(this.selectedMonth);
                const day = this.setTimeFormat(this.daySelected);
                const date = `${this.year}-${month}-${day}`
                console.log('date::');
                console.log(date);
                const formatDate = new Date(date);
                this.toDate = formatDate.toISOString();


                // TODO::CAMBIAR FORMATO DE FECHA A ISO TO STRING
                if (this.to != '') {
                    this.isDisabled = false;
                } else {
                    this.isDisabled = true;
                }
                return true;
            }
        },
        setTimeFormat(time){ 
            return time < 10 
             ? '0'+ time 
             : time
        },
        obtenerDiasDelMes(mes, año) {
            mes = mes - 1;
            const ultimaFechaDelMes = new Date(año, mes + 1, 0);
            const numeroDeDias = ultimaFechaDelMes.getDate();
            const listaDeDias = [];
            for (let dia = 1; dia <= numeroDeDias; dia++) {
                listaDeDias.push(dia);
            }

            return listaDeDias;
        },
        initAutocomplete() { 
            var autocomplete; 
            // eslint-disable-next-line no-undef
            autocomplete = new google.maps.places.Autocomplete( this.$refs.inputSearchRef, 
            { 
                types: ['locality', 'political'], 
                // componentRestrictions: { 'country': ['es'] }, 
                fields: ['place_id', 'geometry', 'name', 'address_components', 'formatted_address', 'adr_address'], } ); 
                autocomplete.addListener('place_changed', () => { 
                    var dataCitySelected = autocomplete.getPlace(); 
                    console.log('dataCitySelected::');
                    console.log(dataCitySelected);
                    this.to = dataCitySelected.formatted_address;
                    this.toLocation = {
                        latitude: dataCitySelected.geometry.location.lat(),
                        longitude: dataCitySelected.geometry.location.lng()
                    };
                });
            },
        validateYear() {
            
                    const yearPattern  = /^\d{4}$/;
                    if (yearPattern .test(this.year)) {
                        this.isValidYear = true;
                        console.log('año valido');
                        console.log(this.year);
                        if (this.verifyMonthAndYear()) {
                            this.days = this.obtenerDiasDelMes(this.selectedMonth, this.year);
                            this.verifyDay();
                            this.verifyDate();
                        }
                    } else {
                        this.isValidYear = false;
                        this.isDisabled = true;
                        console.log('año no valido');
                        console.log(this.year);
                    }
                },
        addPhotos(){
            this.withImages = true
            console.log('agregando fotos::');
        },
        async save(){
            this.guardando = true;
            const params = {
                from : this.from,
                fromDate: this.fromDate,
                fromLocation: this.fromLocation,
                to : this.to,
                toDate: this.toDate,
                toLocation: this.toLocation,
                publicTrip: this.publicTrip,
                images: this.urlImagenes
            }

            try {
                await travelService.create(params);
                this.to = '';
                this.toDate = '';
                this.toLocation = {};
                this.guardando = false;
                this.$router.push('/travels');
            } catch (error) {
                this.guardando = false;
                error.log(error);
            }
        },
        cancel(){
            //this.$emit('cancel');
            this.$router.push('/travels');

        },
        actualizarImagenes(urls){
            this.urlImagenes=urls;
            console.log("arreglo de imagenes");
            console.log(this.urlImagenes);
        },
    },
    watch: {
        year(){
            this.validateYear();
        },
        daySelected(){
            console.log('dia::');
            console.log(this.daySelected);
            this.verifyDate();
        },
        selectedMonth(){
            console.log('mes::');
            console.log(this.selectedMonth);
            if (this.verifyMonthAndYear()) {
                this.days = this.obtenerDiasDelMes(this.selectedMonth, this.year);
                this.verifyDay();
               this.verifyDate()
            }
        },
        to(){
            this.verifyDate()
        }
    }
}
</script>

<style>
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #a8b1b7;
}
.cancelBtn{
    cursor: pointer;
    font-size: 14px;
}
.inputPlaces{
    border: solid 1px red;
    border-radius: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 10px;
    padding-right: 10px;
}

.w400{
    width: 400px;
}
.w100{
    width: 100px;
}
.w150{
    width: 150px;
}
.w200{
    width: 200px;
}
.w50{
    width: 80px;
}
.cp{
    padding-top: 10px;
    padding-bottom: 10px;
}

.labelAddFotos {
    text-decoration: underline;
    cursor: pointer;
}

</style>