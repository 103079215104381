import { BehaviorSubject } from 'rxjs';
import axios from 'axios';

const baseUrl = `${process.env.VUE_APP_API_URL}`;
//const baseUrlExperiences = `${process.env.VUE_APP_API_URL}/api/statistics/experience`;
const travelSubject = new BehaviorSubject(null);


export const travelService = {
    getPastTravels,
    getTravel,
    create,
    getById,
    getStadistics,
    update,
    addPhotos,
    removePhotos,
    delete: _delete,
    getGeoMakers,
    experience: travelSubject.asObservable(),
    get experienceValue () { return travelSubject.value; }
};

function getPastTravels(pagina, itemsPerPage, idUser) {
    console.log("buscando experiencias");
    if (idUser== null || idUser == '') {
        return axios.get(`${baseUrl}/api/past/travels/`+pagina+`/`+itemsPerPage+``, {withCredentials: true})
        .then(response => response.data);
    } else {
        return axios.get(`${baseUrl}/api/past/travels/`+idUser+`/`+pagina+`/`+itemsPerPage+``, {withCredentials: true})
        .then(response => response.data);
    }
}

function getTravel(id) {
    console.log("buscando experiencias");
    return axios.get(`${baseUrl}/api/travels/`+id+``, {withCredentials: true})
        .then(response => response.data);
}

async function create(params) {
    const response = await axios.post(`${baseUrl}/api/past/travels`, params , {withCredentials: true});
    let responseData = response.data;
    return responseData;
}


function getGeoMakers(idUser) {
    console.log("buscando makers::");
    if(idUser == null || idUser == '') {
        return axios.get(`${baseUrl}/api/travels/geodata`, {withCredentials: true})
        .then(response => response.data);
    } else {
        return axios.get(`${baseUrl}/api/travels/geodata/${idUser}`, {withCredentials: true})
        .then(response => response.data);
    }

    
        
}

function getById(id) {
    console.log("buscando experiencia: "+id);
    return axios.get(`${baseUrl}/${id}`, {withCredentials: true})
        .then(response => response.data);
}

function getStadistics(url) {
    return axios.get(`${process.env.VUE_APP_API_URL}`+url, {withCredentials: true})
        .then(response => response);
}

async function update(params) {
    const response = await axios.put(`${baseUrl}`, params, {withCredentials: true});
    let experience = response.data;
    // update the current account if it was updated
    /*if (experience.id === experienceSubject.value?.id) {
        // publish updated account to subscribers
        experience = { ...experienceSubject.value, ...experience };
        experienceSubject.next(experience);
    }*/
    return experience;
}

async function addPhotos(travelId, params) {
    const response = await axios.patch(`${baseUrl}/api/travels/add/photo/`+travelId, params, {withCredentials: true});
    let experience = response.data;
    return experience;
}

async function removePhotos(travelId, params) {
    const response = await axios.patch(`${baseUrl}/api/travels/remove/photo/`+travelId, params, {withCredentials: true});
    let experience = response.data;
    return experience;
}

async function _delete(id) {
    await axios.delete(`${baseUrl}/${id}`, {withCredentials: true});
    /*if (id === experienceSubject.value?.id) {
        // auto logout if the logged in account was deleted
        logout();
    }*/
}