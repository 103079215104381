import { BehaviorSubject } from 'rxjs';
import axios from 'axios';

import { router } from '@/_helpers';

const baseUrlLogin = `${process.env.VUE_APP_API_URL}/api`;

const baseUrlLoginV2 = `${process.env.VUE_APP_API_URL}/api/v2/oauth/authorizations`;
const baseUrlLoginAndRegister = `${process.env.VUE_APP_API_URL}/api/oauth/authorizations`;
import Swal from 'sweetalert2';

const authenticationSubject = new BehaviorSubject(null);

export const authenticationService = {
    loginFB,
    registerFB,
    apiAuthenticate,
    loginUP,
    apiAuthenticateUP,
    logout,
    getAll,
    getById,
    update,
    delete: _delete,
    getInfoUser,
    register,
    authentication: authenticationSubject.asObservable(),
    get authenticationValue () { return authenticationSubject.value; }
};

function getInfoUser(idUser) {
    console.log("info::");
    if(idUser == null || idUser == '') {
        return axios.get(`${baseUrlLogin}/core/user`, {withCredentials: true});
    } else {
        return axios.get(`${baseUrlLogin}/core/user/${idUser}`, {withCredentials: true});
    }
        // .then(response => response.data);
}

async function loginFB() {
    const { authResponse } = await new Promise(FB.login);
    if (!authResponse) return;
    await apiAuthenticate(authResponse.accessToken);
    const returnUrl = router.history.current.query['returnUrl'] || '/travels';
    router.push(returnUrl).catch((err) => {
        console.log("error");
        console.log(err);
    });
}

async function apiAuthenticate(accessToken) {
    console.log("1");
    const response = await axios.post(`${baseUrlLoginV2}/facebook`, { accessToken },{withCredentials: true});
    console.log("2");
    console.log(response)
    if(response){
        if( response.data.code == 0) {
            console.log("ENTRANDO AL HOME");
            const authentication = response.data;
            sessionStorage.setItem('authentication', JSON.stringify(authentication));
            authenticationSubject.next(authentication);
            return authentication;
        }
        else if(response.data.code == 404 && response.data.message == 'UNREGISTERED_USER'){
            console.log("enviando a registro");
            Swal.fire({
                title: 'Usuario no registrado',
                text: "Aun no tienes una cuenta en LetZTrav, pero podemos crear uno en segundos.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                //cancelButtonColor: '#d33',
                confirmButtonText: 'Si, continuar'
            }).then((result) => {
                if (result.isConfirmed) {
                    router.push('/register');
                }
            })
        } else {
            return
        }
    }else {
        return
    }
}

async function apiAuthenticateUP(user, pwd) {
    const response = await axios.post(`${baseUrlLogin}/authentications`, { user, pwd },{withCredentials: true});
    console.log("LOGUEANDO CON UP");
    console.log(response);
    if(response){
        if(response.data.code == 200 && response.data.message == 'UNREGISTERED_USER') {
            console.log("enviando a registro");
            Swal.fire({
                title: 'Usuario no registrado',
                text: "Aun no tienes una cuenta en LetZTrav, pero podemos crear uno en segundos.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                //cancelButtonColor: '#d33',
                confirmButtonText: 'Si, continuar'
            }).then((result) => {
                if (result.isConfirmed) {
                    router.push('/register');
                }
            })
        } else {
            const authentication = response.data;
            authenticationSubject.next(authentication);
            sessionStorage.setItem('authentication', JSON.stringify(authentication));
            return authentication;
        }
        
    } else {
        return
    }

}

async function registerFB() {
    const { authResponse } = await new Promise(FB.login);
    if (!authResponse) return;
    await apiAuthenticateAndRegister(authResponse.accessToken);
    const returnUrl = router.history.current.query['returnUrl'] || '/travels';
    router.push(returnUrl).catch((err) => {
        console.log("error");
        console.log(err);
    });
}

async function apiAuthenticateAndRegister(accessToken) {
    console.log("1");
    const response = await axios.post(`${baseUrlLoginAndRegister}/facebook`, { accessToken },{withCredentials: true});
    console.log("2");
    console.log(response)
    if(response){
        const authentication = response.data;
        sessionStorage.setItem('authentication', JSON.stringify(authentication));
        authenticationSubject.next(authentication);
        return authentication;
    }else {
        return
    }
}

function register(params) {
    try {
        return axios.post(`${baseUrlLogin}/users/registrations`, 
            {
                lastName: params.lastName,
                firstName: params.firstName,
                deviceId: params.deviceId,
                motherLastName: params.motherLastName,
                birthdate: params.birthdate,
                appOS: params.appOS,
                location: params.location,
                password: params.password,
                city: params.city,
                biography: params.biography,
                country: params.country,
                gender: params.gender,
                email: params.email
            }, 
            {
                headers:{
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        );
    
    } catch (error) {
        error.log(error);    
    }
}

async function loginUP(user, pwd) {
    try {
        await apiAuthenticateUP(user, pwd);
        const returnUrl = router.history.current.query['returnUrl'] || '/travels';
        router.push(returnUrl);
    } catch (error) {
        console.error(error);
    }
}

function logout() {
    // revoke app permissions to logout completely because FB.logout() doesn't remove FB cookie
    FB.api('/me/permissions', 'delete', null, () => FB.logout());
    stopAuthenticateTimer();
    axios.get(`${baseUrlLogin}/logout`, {withCredentials: true});
    authenticationSubject.next(null);
    sessionStorage.clear();
    router.push('/login');
}

function getAll() {
    return axios.get(baseUrlLogin)
        .then(response => response.data);
}

function getById(id) {
    return axios.get(`${baseUrlLogin}/${id}`)
        .then(response => response.data);
}

async function update(id, params) {
    const response = await axios.put(`${baseUrlLogin}/${id}`, params);
    let authentication = response.data;
    // update the current account if it was updated
    if (authentication.id === authenticationSubject.value?.id) {
        // publish updated account to subscribers
        authentication = { ...authenticationSubject.value, ...authentication };
        authenticationSubject.next(authentication);
    }
    return authentication;
}

async function _delete(id) {
    await axios.delete(`${baseUrlLogin}/${id}`);
    if (id === authenticationSubject.value?.id) {
        // auto logout if the logged in account was deleted
        logout();
    }
}

// helper methods

let authenticateTimeout;

/*function startAuthenticateTimer() {
    // parse json object from base64 encoded jwt token
    console.log("otra vez por aqui");
    const jwtToken = JSON.parse(atob(authenticationSubject.value.token.split('.')[1]));

    // set a timeout to re-authenticate with the api one minute before the token expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    const { accessToken } = FB.getAuthResponse();
    console.log("otra vez por aqui");
    authenticateTimeout = setTimeout(() => apiAuthenticate(accessToken), timeout);
}
*/
function stopAuthenticateTimer() {
    // cancel timer for re-authenticating with the api
    clearTimeout(authenticateTimeout);
}