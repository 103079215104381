<template>
  <div class="bodyhome" id="app">
    <NavBar ref="navBarRef"></NavBar>
    <div class="separador"></div>
    <router-view @menuCheck="$refs.navBarRef.getInfoUser()"></router-view>
  </div>
</template>

<script>
  import NavBar from '@/components/NavBar'
  import { authenticationService } from '@/_services';
// import Home from './home/Home.vue'
export default {
  name: 'App',
  components: {
    NavBar,
    // Home
  },
  methods: {
        logout: authenticationService.logout
    }
}
</script>

<style>
@import './assets/css/style-letz.css';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
