<template>
    <div class="change-photo-message">
        <input type="file" accept="image/*" class="hidden" ref="file" @change="change" hidden>
        <div class="">
            <button class="title-profile-icon" @click="browse()">
                <icon class="title-profile-icon" name="camera"></icon>
            </button>
        </div>
        <div v-if="newPhoto == ''">
            <img v-if="photo == ''" src="@/assets/images/foto-icon.png" class="img-fluid" alt="">
            <img v-if="photo != ''" :src="photo" class="img-fluid" alt="">
        </div>
        <div v-else>
            <img :src="newPhoto" class="img-fluid" alt="">
        </div>
    </div>
</template>
<script>
import Icon from '@/components/Icon'
import { userService } from '@/_services';

export default {
    props: ['photo'],
    data() {
        return {
            src: "@/assets/LetZTrav_nbg-010.png",
            newPhoto: ''
        }
    },
    components: {
        Icon
    },
    methods: {
        browse() {
            this.$refs.file.click();
        },
        change(e) {
            this.newPhoto = URL.createObjectURL(e.target.files[0])
            userService.saveProfilePicture(e.target.files[0]);
            //this.emit('input', e.target.files[0]);
            /*let reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            this.src.push({url:url, name:data.name, size:files[i].size, type:files[i].type});
            reader.onload = (e) => {
                this.src = e.target.result;
                console.log(this.src);
            }*/
        }
    }
}
</script>
<style>
.rounced-full {
    border-radius: 50%;
    width: 200px;
}

.title-profile-photo {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.25);
    width: 200px;
    height: 100%;
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title-profile-icon {
    position: absolute;
    color: white;
    background-color: rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    width: 50px;
    height: 50px;
}
</style>