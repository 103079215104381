<template>
    <div>
        <Navegador></Navegador>
        <hr>
        <span v-if="cargando" class="spinner-border spinner-border-sm"></span>
        <div v-else class="row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 informacionn">
                <div class="row">
                    <div class="col-12 nombreexperiencia">
                        {{name}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="mapouter">
                            <div class="gmap_canvas">
                                <iframe width="100%" height="400" id="gmap_canvas" :src="'https://maps.google.com/maps?q='+location+'&t=&z=13&ie=UTF8&iwloc=&output=embed'" frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                                </iframe>
                                <a href="https://putlocker-is.org"></a>
                                <br>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <label class="precios">Rango de precios</label>
                        <label class="precios">${{priceMin}} - ${{priceMax}}</label>
                    </div>
                </div>
                <div class="row">
                        <!--<router-link :to="`/edit/${experience.id}`" class="btn btn-primary follow azulletztrav">Editar
                        </router-link>-->
                        <div class="col-2"></div>
                        <div class="col-4">
                            <button @click="editExperience()" class="btn btn-primary follow azulletztrav">Editar</button>
                        </div>
                        <div class="col-4">
                            <button @click="ddeleteExperience()" class="btn btn-danger follow rosaletztrav">Eliminar</button>
                        </div>
                        <div class="col-2"></div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-8 barralateral barraimgen">
                <img class="imagegalery" v-for="(image, i) in images" :src="image.larger" :key="i" @click="index = i">
        <vue-gallery-slideshow :images="imagesLarge" :index="index" @close="index = null"></vue-gallery-slideshow>
            </div>
        </div>
        <hr class="row">
        <div class="row">
            <div class="col-12 estadistics">
                ESTADISTICAS
            </div>
        </div>
        <div class="row barralateral">
            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
                <CardDoughnut @eliminar="deleteExperience" :experience="experiencia"></CardDoughnut>
            </div>
            <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-6">
                <CardBars @eliminar="deleteExperience" :experience="experiencia"></CardBars>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                <CardDots @eliminar="deleteExperience" :experience="experiencia"></CardDots>
            </div>
        </div>
    </div>
</template>
<script>
    import Navegador from '@/components/Navegador'
    import { router } from '@/_helpers';
    //import { authenticationService } from '@/_services';
    import { experienceService } from '@/_services';
    //import { categoryService } from '@/_services';
    import Swal from 'sweetalert2';
    //import { PhotoCollageWrapper } from 'vue-photo-collage'
    //import VueGallerySlideshow from 'vue-gallery-slideshow';
    import VueGallerySlideshow from 'vue-gallery-slideshow';
    import CardDoughnut from '@/components/utils/CardDoughnut'
    import CardDots from '@/components/utils/CardDots'
    import CardBars from '@/components/utils/CardBars'
    
    export default {
        data() {
            return {
                name:null,
                location:null,
                priceMin:null,
                priceMax:null,
                category:'',
                categories:[],
                cargando:true,
                images: [],
                imagesLarge: [],
                index: null,
                experiencia:null
            }
        },
        created() {
            //categoryService.getAll().then(x => this.categories = x);
            experienceService.getById(this.$route.params.id)
            .then(x => {
            this.name=x.name;
            this.location=x.location;
            this.priceMin=x.prices.min;
            this.priceMax=x.prices.max;
            this.category=x.category;
            this.cargando=false;
            this.images=x.images;
            this.experiencia=x;
            x.images.forEach(img => {
                this.imagesLarge.push(img.larger)
            });
            });
        },
        components: {
            Navegador,
            VueGallerySlideshow,
            CardDoughnut,
            CardDots,
            CardBars
            //PhotoCollageWrapper,
            //VueGallerySlideshow
        },
        methods: {
            editExperience(){
                router.push('/edit/'+this.experiencia.id);
            },
            ddeleteExperience() {
                Swal.fire({
                    title: '¿Desea eliminar la experiencia seleccionada?',
                    text: "",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, continuar'
                }).then((result) => {
                    if (result.value) {
                        // show deleting spinner
                /*this.experiences = this.experiences.map(x => {
                    if (x.id === id) x.isDeleting = true;
                    return x;
                });*/

                // delete account
                experienceService.delete(this.experiencia.id)
                    .then(() => {
                        router.push('/travels');
                        /*this.experiences = this.experiences.filter(x => x.id !== id)
                        this.experiencesMovil = this.experiencesMovil.filter(x => x.id !== id)*/
                    });
                        Swal.fire(
                            'Exito!',
                            'Se ha eliminado la experiencia',
                            'success'
                        )
                    }else{
                        console.log("cancelo");
                    }
                })
                
            },
            itemClickHandler(data, i) {
                console.log(data);
                console.log(i)
            },
            actualizarExperiencia(){
                console.log("creando nueva experiencia:::,,");
                var request={
                    id:this.$route.params.id,
                    name:this.name,
                    location:this.location,
                    prices:{min:this.priceMin, max:this.priceMax},
                    category:this.category
                };
                console.log(request);
                experienceService.update(request).then(() => {
                    console.log("regresanddoooooo");
                    Swal.fire(
              'Actualización completa',
              'La experiencia se actualizó correctamente',
              'success'
				);
                    router.push('../');
                })
                .catch(error => {
                    this.error = error;
                    this.loading = false;
                });
            }
        }
    }
    </script>
<style>
    .precios{
        font-size: 50px;
    }
    .informacionn{
        background-color: #f8f8f8;
        padding-bottom: 10px;
    }
    .barralateral{
        background-color: #EFEFEF;
        padding-bottom: 10px;
    }
    .nombreexperiencia{
        background-color: #003686;
        color: white;
        padding: 15px;
        border-radius: 15px;
        margin-top: 10px;
    }

    .estadistics{
        background-color: #6d88af;
        color: white;
        padding: 5px;
        border-radius: 15px;
        margin-top: 10px;
    }
    .imagegalery{
        height: 250px;
        width: 250px;
        margin: 10px;
    }
    a.txt{
        display: block;
        text-align: center;
        text-decoration: none;
        color: #999;
        font-size: 0.7rem;
        transition: .3s;
    }

    .img2{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .img2 img{
        width: 400px;
    }

    @media screen and (max-width: 1000px){
	.formm{
		width: 290px;
	}

	.login-content h2{
        font-size: 2.4rem;
        margin: 8px 0;
	}
}

@media screen and (max-width: 900px){
	.container{
		grid-template-columns: 1fr;
	}

	.img2{
		display: none;
	}

	.wave{
		display: none;
	}

	.login-content{
		justify-content: center;
	}
}

    

    a.txtCrear{
        display: block;
        text-align: center;
        text-decoration: none;
        color: #3B5998;
        font-size: 0.9rem;
        transition: .3s;
    }
    .title{
        color: #00369b;
        font-size: large;
    }
    .input-divf{
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .textForm2{
        border-radius: 10px;
        border: 1px solid #0aaebd;
        font-size:medium;
        padding-right: 10px;
        padding-left: 10px;
    }
    .textForm3{
        border-radius: 10px;
        border: 1px solid #0aaebd;
        font-size:medium;
        padding-right: 10px;
        padding-left: 10px;
        margin-right: 2px;
        margin-left: 2px;
        width: 46%;
    }
    .formularioletz{
        width: 50%;
    }
    .btnSiguiente{
        text-align: center;
        display: block;
        width: 100%;
        height: 35px;
        border-radius: 10px;
        outline: none;
        border: none;
        background-image: linear-gradient(to right, #0aaebd, #0aaebd, #68d9e4);
        background-size: 200%;
        font-size: 1rem;
        color: #fff;
        font-family: 'Poppins', sans-serif;
        margin: 1rem 0;
        cursor: pointer;
        transition: .5s;
    }
    .btnSiguiente:hover{
        background-position: right;
    }
</style>
