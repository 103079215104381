<template>
    <div>
        <div class="container">
            <div class="izquierdo" v-if="urlImagenes.length == 0 && imagesToRemove.length == 0">
                <button class="button-letztrav-pink-ligth" @click="regresar">Regresar</button>
            </div>
            <div>
                <div class="row" v-if="urlImagenes.length > 0 || imagesToRemove.length > 0">
                    <div class="col-6">
                        <button @click="descartarCambios" class="button-letztrav-pink">Cancelar</button>
                    </div>
                    <div class="col-6">
                        <button @click="guardarCambios" class="button-letztrav-blue"><span v-if="!actualizandoImagenes">Guardar</span><span v-else class="spinner-border spinner-border-lg align-center"></span></button>
                    </div>
                </div>
                <div style="display: inline;" class="imagegalery-letz">
                    <label for="images-upload" class="images-upload">
                        <svg class="custum-icon" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                            preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                            <g fill="none">
                                <path
                                    d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11s11-4.925 11-11S18.075 1 12 1zm1 15a1 1 0 1 1-2 0v-3H8a1 1 0 1 1 0-2h3V8a1 1 0 1 1 2 0v3h3a1 1 0 1 1 0 2h-3v3z"
                                    fill="currentColor" />
                            </g>
                        </svg>
                    </label>
                    <input @change="fileChange" id="images-upload" type="file" accept="image/*" multiple hidden>
                </div>
                <div style="display: inline;" v-for="image in newImages" :key="image.id" class="cuadrado">
                    <img class="imagegalery-letz" :src="image.url">
                    <div v-if="loading" class="loadingClass">
                        <span class="spinner-border spinner-border-lg align-center"></span>
                    </div>
                    <span class="etiqueta">NEW</span>
                    <button @click="removeNew(i)" class="close-btn" type="button">
                        <svg class='times-icon' xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                            preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20">
                            <g fill="none">
                                <path d="M7.172 14.243a1 1 0 1 1-1.415-1.415l7.071-7.07a1 1 0 0 1 1.415 1.414l-7.071 7.07z"
                                    fill="currentColor" />
                                <path d="M5.757 7.172a1 1 0 1 1 1.415-1.415l7.07 7.071a1 1 0 0 1-1.414 1.415l-7.07-7.071z"
                                    fill="currentColor" />
                            </g>
                        </svg>
                    </button>
                </div>
                <div style="display: inline;" v-for="image in newImagesPending" :key="image.id" class="cuadrado">
                    <img class="imagegalery-letz" :src="image.url">
                    <div v-if="loading" class="loadingClass">
                        <span class="spinner-border spinner-border-lg align-center"></span>
                    </div>
                    <span class="etiqueta">NEW</span>
                    <button class="close-btn" type="button">
                        <svg class='times-icon' xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                            preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20">
                            <g fill="none">
                                <path d="M7.172 14.243a1 1 0 1 1-1.415-1.415l7.071-7.07a1 1 0 0 1 1.415 1.414l-7.071 7.07z"
                                    fill="currentColor" />
                                <path d="M5.757 7.172a1 1 0 1 1 1.415-1.415l7.07 7.071a1 1 0 0 1-1.414 1.415l-7.07-7.071z"
                                    fill="currentColor" />
                            </g>
                        </svg>
                    </button>
                </div>
                <div style="display: inline;" v-for="(image, i) in imagesLarge" :key="i">
                    <img class="imagegalery-letz" :src="image" @click="index = i">
                    <button @click="removeCurrent(i)" class="close-btn" type="button">
                        <svg class='times-icon' xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                            preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20">
                            <g fill="none">
                                <path d="M7.172 14.243a1 1 0 1 1-1.415-1.415l7.071-7.07a1 1 0 0 1 1.415 1.414l-7.071 7.07z"
                                    fill="currentColor" />
                                <path d="M5.757 7.172a1 1 0 1 1 1.415-1.415l7.07 7.071a1 1 0 0 1-1.414 1.415l-7.07-7.071z"
                                    fill="currentColor" />
                            </g>
                        </svg>
                    </button>
                </div>
            </div>
            <vue-gallery-slideshow :images="imagesLarge" :index="index" @close="index = null"></vue-gallery-slideshow>
        </div>

    </div>
</template>
<script>
import VueGallerySlideshow from 'vue-gallery-slideshow';
import { experienceService } from '@/_services';
import axios from 'axios';
export default {
    data() {
        return {
            id: this.$route.params.id,
            images: [],
            newImages: [],
            imagesLarge: [],
            imagesToRemove: [],
            newImagesPending: [],
            loader: true,
            loading: true,
            scrollContainer: null,
            page: 0,
            itemsPerPage: 10,
            infoPagination: {},
            travels: [],
            index: null,
            viewGalery: true,
            addImages: false,
            actualizandoImagenes: false,
            imagenes: [],
            urlImagenes: [],
            server: "https://api-dev.letztrav.com/api/file/temporal"
        }
    },
    mounted() {
        this.getExperience().then((response) => {
            console.log(`Received response: ${response}`);
            this.images = response.images
            console.log(this.images);
            this.scrollContainer = this.$refs.scrollContainer;
            console.log("MOUNTED");
            this.images.forEach(img => {
                this.imagesLarge.push(img.larger)
            });
            this.imagesLarge.reverse();
        });
    },
    destroyed() {
    },
    components: {
        VueGallerySlideshow,
    },
    watch: {
        /*images: function () {
            console.log("WATCHED");
            this.imagesLarge = [];
            this.images.forEach(img => {
                this.imagesLarge.push(img.larger)
            });
            this.imagesLarge.reverse();
        }*/
    },
    methods: {
        async getExperience() {
            try {
                this.loader = true;
                console.log("Buscando experiencia con id: "+this.$route.params.id);
                const res = await experienceService.getExperience(this.$route.params.id);
                this.images = res.images
                this.loader = false;
                return res
            } catch (error) {
                console.log(error);
                this.loader = false;
            }

        },
        async guardarCambios() {
            this.actualizandoImagenes = true;
            console.log("guardando cambios")
            this.newImages.forEach(img => {
                this.imagesLarge.unshift(img.url)
            });
            this.newImages = []
            //this.$emit('guardar', this.id, this.urlImagenes, this.imagesToRemove, this.imagesLarge);
            await this.guardar(this.id, this.urlImagenes, this.imagesToRemove, this.imagesLarge);
            this.imagesToRemove = []
            this.urlImagenes = []
            this.actualizandoImagenes = false;
        },

        async guardar(id, urlImagenes, imagesToRemove, imagesToUpdate) {
          if(urlImagenes.length>0) {
            console.log("guardando nuevas fotos");
            console.log(id);
            console.log(urlImagenes);
            try{
                await experienceService.addPhotos(id, {images: urlImagenes});
            }catch(error) {
                this.actualizandoImagenes = false;
              console.log(error);
            }
          }
          if(imagesToRemove.length>0) {
            console.log("borrando fotos");
            console.log(id);
            console.log(imagesToRemove);
            try{
                await experienceService.removePhotos(id, {images: imagesToRemove});
            }catch(error) {
                this.actualizandoImagenes = false;
              console.log(error);
            }
          }
          console.log("actualizando imagenes de viajes");
          await this.travels.forEach(travel => {
            if (travel.id === id) {
              travel.images=[]
              imagesToUpdate.forEach(img => {
                travel.images.push({larger:img});
              });
            }
          });

        },

        descartarCambios() {
            this.imagesLarge = [];
            this.images.forEach(img => {
                this.imagesLarge.push(img.larger)
            });
            this.imagesLarge.reverse();
            this.newImages = [];
            this.urlImagenes = [];
            this.imagesToRemove = [];
        },
        removeNew(index) {
            this.newImages.splice(index, 1)
            this.urlImagenes.splice(index, 1)
        },
        removeCurrent(index) {
            var img = this.imagesLarge.splice(index, 1)
            this.imagesToRemove.push(img[0])
            console.log(this.imagesToRemove);
        },
        regresar() {
            //this.$router.push('/experiences');
            window.history.back();
        },
        addImagesFunc() {
            this.addImages = true
        },
        cancelImagesFunc() {
            this.addImages = false
        },
        async fileChange(event) {
            this.loading = true
            let files = event.target.files
            for (var i = 0; i < files.length; i++) {
                let formData = new FormData
                let url = URL.createObjectURL(files[i])
                formData.set('file', files[i])
                //this.newImages.push({ url: url, size: files[i].size, type: files[i].type });
                this.newImagesPending.push({});
                const { data } = await axios.post(this.server, formData, { withCredentials: true })
                this.urlImagenes.push(data.id);
                this.newImages.push({id: data.id, url: url, name: data.name, size: files[i].size, type: files[i].type });
                this.newImagesPending.pop();
            }
            this.loading = false
            //this.media_emit()
        },
    },
}
</script>
<style>
.izquierdo {
    text-align: left;
}

.etiqueta {
    position: absolute;
    top: -65px;
    left: 10px;
    background-color: #00ff00;
    /* Color verde */
    color: #fff;
    /* Color del texto */
    padding: 5px 10px;
    /* Ajusta el relleno según tus necesidades */
    border-radius: 5px;
    /* Añade esquinas redondeadas al cuadro de la etiqueta */
}

.loadingClass {
    position: absolute;
    top: 0px;
    left: 65px;
    color: #fff;
}

.cuadrado {
    position: relative;
}

.elements-wraper {
    padding: 1rem !important;
    display: flex !important;
    flex-wrap: wrap !important;

}

.imagegalery-letz {
    height: 150px;
    width: 150px;
    margin: 10px;
}

.image-wraper {
    min-height: 200px !important;

}



.images-upload {
    background-color: #ffffff !important;
    border-radius: 5px !important;
    border: 1px dashed #ccc !important;
    display: inline-block !important;
    cursor: pointer !important;
    width: 165px !important;
    height: 150px !important;
}

.images-upload:hover {
    background-color: #f1f1f1 !important;
}

.image-container {
    display: inline-table !important;
    height: 90px !important;
    width: 140px !important;
    display: flex !important;
}

.images-preview {
    border-radius: 5px !important;
    border: 1px solid #ccc !important;
    display: inline-block !important;
    width: 140px !important;
    height: 88px !important;
    padding-top: -14px !important;
    transition: filter 0.1s linear;

}

.images-preview:hover {
    filter: brightness(90%);
}

.button-container {
    display: inline-flex !important;
    height: 150px !important;
    width: 150px !important;
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
}

.close-btn {
    background: none !important;
    color: white !important;
    border: none !important;
    padding: 0px !important;
    margin: 0px !important;
    font: inherit !important;
    cursor: pointer !important;
    outline: inherit !important;
    position: relative !important;
    left: -50px !important;
    top: -60px !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
    width: 0px !important;

}

.times-icon {
    font-size: 3rem !important;
    padding: 0px !important;
    margin: 0px !important;
}

.custum-icon {
    color: #00afca !important;
    font-size: 3rem !important;
    margin-top: 50px !important;
    margin-bottom: 50px !important;
    margin-left: 60px !important;

}

.custum-icon:hover {
    color: #29818f !important;
}

.close-btn:hover {
    color: red !important;
    box-shadow: red 0px 7px 29px 0px !important;
}


/* -------------------- */


.width-100 {
    width: 100% !important;
}

.red-border {
    border: 1px solid #dc3545 !important;
    border-color: #dc3545 !important;
}

.elements-wraper {
    padding: 1rem !important;
    display: flex !important;
    flex-wrap: wrap !important;

}

.align-center {
    text-align: center !important;
}

.m-top-1 {
    margin-top: 0.25rem !important;
}



.red-text {
    color: #d82335;
}
</style>