import { BehaviorSubject } from 'rxjs';
import axios from 'axios';

const baseUrl = `${process.env.VUE_APP_API_URL}/api/catalog/categories`;
const categorySubject = new BehaviorSubject(null);


export const categoryService = {
    getAll,
    create,
    getById,
    update,
    delete: _delete,
    category: categorySubject.asObservable(),
    get categoryValue () { return categorySubject.value; }
};

function getAll() {
    console.log("buscando experiencias");
    return axios.get(`${baseUrl}/all`, {withCredentials: true})
        .then(response => response.data);
}

async function create(category) {
    const response = await axios.post(`${baseUrl}`, category , {withCredentials: true});
    let responseData = response.data;
    return responseData;
}

function getById(id) {
    return axios.get(`${baseUrl}/${id}`, {withCredentials: true})
        .then(response => response.data);
}

async function update(id, params) {
    const response = await axios.put(`${baseUrl}/${id}`, params, {withCredentials: true});
    let category = response.data;
    // update the current account if it was updated
    if (category.id === categorySubject.value?.id) {
        // publish updated account to subscribers
        category = { ...categorySubject.value, ...category };
        categorySubject.next(category);
    }
    return category;
}

async function _delete(id) {
    await axios.delete(`${baseUrl}/${id}`, {withCredentials: true});
    /*if (id === categorySubject.value?.id) {
        // auto logout if the logged in account was deleted
        logout();
    }*/
}