import { BehaviorSubject } from 'rxjs';
import axios from 'axios';

const baseUrl = `${process.env.VUE_APP_API_URL}/api/account`;
const accountSubject = new BehaviorSubject(null);


export const accountService = {
    requestDelete,
    confirmDelete,
    account: accountSubject.asObservable(),
    get accountValue () { return accountSubject.value; }
};

async function requestDelete() {
    const response =await axios.post(`${baseUrl}/delete`, {} , {withCredentials: true});
    let responseData = response.data;
    console.log(responseData);
    return responseData;
}

async function confirmDelete(code) {
    const response = await axios.delete(`${baseUrl}/delete/${code}`, {withCredentials: true});
    console.log(response);
    let responseData = response.data;
    return responseData;
}
