<template>
    <div>
        <Navegador></Navegador>
        <span v-if="cargando" class="spinner-border spinner-border-sm"></span>
        <div v-else class="row">
            <div class="col-6 img2">
                <div>
                    <img src="https://letzgeneral-public.s3.us-east-1.amazonaws.com/webletztrav/LetZTrav_nbg-010.png">
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
                <div class="login-content">
                    <form class="formularioletz">
                        <b class="title">Editar Experiencia</b>
                        <hr size="10px" color="black" />
                        <div class="input-divf">
                            <input class="form-control textForm2" id="name" v-model="name" type="text" placeholder="Nombre">
                        </div>
                        <div class="input-divf">
                            <input class="form-control textForm2" id="location" v-model="location"  type="text" placeholder="Ubicacion">
                        </div>
                        <div class="input-divf row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <input class="form-control textForm2" id="priceMin" step="500" v-model="priceMin"  value="500" type="number" placeholder="Precio Minimo">
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <input class="form-control textForm2" id="priceMax" step="500" v-model="priceMax"  value="1000" type="number" placeholder="Precio Maximo">
                            </div>
                        </div>
                        <div class="input-divf">
                            <select class="form-control textForm2" data-live-search="true" v-model="category">
                                <option disabled value="">Seleccione una categoría</option>
                                <option v-for="option in categories" :key="option.id" v-bind:value="option.id">
                                    {{ option.name }}
                                </option>
                            </select>
                        </div>
                        <hr size="10px" color="black" />
                        <input @click="actualizarExperiencia" class="btnSiguiente" value="Actualizar">
                    </form>
                </div>
            </div>
        </div>
        <br>
        <div v-if="!cargando" class="row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-12 col-xl-12">
                <UploadImage 
            server="https://api-dev.letztrav.com/api/file/temporal"
            maximo=15
            :actualGalery="imagesLarge"
            @imagenes="actualizarImagenes"
            @imagenesRm="removeImagenes"
            >

            </UploadImage>
            </div>
            
        </div>
    </div>
</template>
<script>
    import Navegador from '@/components/Navegador'
    import { router } from '@/_helpers';
    //import { authenticationService } from '@/_services';
    import { experienceService } from '@/_services';
    //import { categoryService } from '@/_services';
    import { categoryService } from '@/_services';
    import Swal from 'sweetalert2';
    import UploadImage from '@/components/utils/UploadImage'
    
    export default {
        data() {
            return {
                name:null,
                location:null,
                priceMin:null,
                priceMax:null,
                category:'',
                categories:[],
                cargando:true,
                imagenes:[],
                imagesLarge:[],
                urlImagenes:[],
                urlImagenesRm:[]
            }
        },
        created() {
            categoryService.getAll()
                .then(x => this.categories = x);
            experienceService.getById(this.$route.params.id)
            .then(x => {this.account = x;
            console.log(x);
            this.name=x.name;
            this.location=x.location;
            this.priceMin=x.prices.min;
            this.priceMax=x.prices.max;
            this.category=x.category;
            this.cargando=false;
            this.imagenes=x.images;
            x.images.forEach(img => {
                this.imagesLarge.push(img.larger)
            });
            });
            console.log("GALERIA ACTUAL");
            console.log(this.actualGalery);
        },
        components: {
            Navegador,
            UploadImage
        },
        methods: {
            actualizarImagenes(urls){
                this.urlImagenes=urls;
                console.log("arreglo de imagenes");
                console.log(this.urlImagenes);
            },
            removeImagenes(urls){
                this.urlImagenesRm.push(urls);
                console.log("imagenes a eliminar");
                console.log(this.urlImagenesRm);
            },
            actualizarExperiencia(){
                console.log("creando nueva experiencia:::,,");
                var request={
                    id:this.$route.params.id,
                    name:this.name,
                    location:this.location,
                    prices:{min:this.priceMin, max:this.priceMax},
                    category:this.category,
                    imagesNew:this.urlImagenes,
                    imagesRm:this.urlImagenesRm
                };
                console.log(request);
                experienceService.update(request).then(() => {
                    console.log("regresanddoooooo");
                    Swal.fire(
              'Actualización completa',
              'La experiencia se actualizó correctamente',
              'success'
				);
                    router.push('../');
                })
                .catch(error => {
                    this.error = error;
                    this.loading = false;
                });
            }
        }
    }
    </script>
<style>
    a.txt{
        display: block;
        text-align: center;
        text-decoration: none;
        color: #999;
        font-size: 0.7rem;
        transition: .3s;
    }

    .img2{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .img2 img{
        width: 400px;
    }

    @media screen and (max-width: 1000px){
	.formm{
		width: 290px;
	}

	.login-content h2{
        font-size: 2.4rem;
        margin: 8px 0;
	}
}

@media screen and (max-width: 900px){
	.container{
		grid-template-columns: 1fr;
	}

	.img2{
		display: none;
	}

	.wave{
		display: none;
	}

	.login-content{
		justify-content: center;
	}
}

    

    a.txtCrear{
        display: block;
        text-align: center;
        text-decoration: none;
        color: #3B5998;
        font-size: 0.9rem;
        transition: .3s;
    }
    .title{
        color: #00369b;
        font-size: large;
    }
    .input-divf{
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .textForm2{
        border-radius: 10px;
        border: 1px solid #0aaebd;
        font-size:medium;
        padding-right: 10px;
        padding-left: 10px;
    }
    .textForm3{
        border-radius: 10px;
        border: 1px solid #0aaebd;
        font-size:medium;
        padding-right: 10px;
        padding-left: 10px;
        margin-right: 2px;
        margin-left: 2px;
        width: 46%;
    }
    .formularioletz{
        width: 50%;
    }
    .btnSiguiente{
        text-align: center;
        display: block;
        width: 100%;
        height: 35px;
        border-radius: 10px;
        outline: none;
        border: none;
        background-image: linear-gradient(to right, #0aaebd, #0aaebd, #68d9e4);
        background-size: 200%;
        font-size: 1rem;
        color: #fff;
        font-family: 'Poppins', sans-serif;
        margin: 1rem 0;
        cursor: pointer;
        transition: .5s;
    }
    .btnSiguiente:hover{
        background-position: right;
    }
</style>