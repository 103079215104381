<template>
    <div>
        <Navegador></Navegador>
        <div class="container">
            <div class="img2">
                <img src="https://letzgeneral-public.s3.us-east-1.amazonaws.com/webletztrav/logoletztrav-removebg-preview.png">
            </div>
            <div class="login-content">
                <form action="index.html">
                    <b class="title">Agregar Nueva Categoría</b>
                    <hr size="10px" color="black" />
                    <div class="input-divf">
                        <input class="form-control textForm" id="name" v-model="name" type="text" placeholder="Nombre de categoría">
                    </div>
                    <div class="input-divf">
                        <textarea class="form-control textForm" id="description" v-model="description" name="description" cols="21" rows="5" placeholder="Descripción"></textarea>
                    </div>
                    <hr size="10px" color="black" />
                    <input @click="socilitarCategoria" class="btnSiguiente" value="Solicitar Categoría">
                </form>                
            </div>
        </div>
    </div>
</template>
<script>
    import Navegador from '@/components/Navegador'
    import { router } from '@/_helpers';
    import { categoryService } from '@/_services';
    //import { accountService } from '@/_services';
    import Swal from 'sweetalert2';
    
    export default {
        data() {
            return {
                name:null,
                description:null
            }
        },
        created() {
            //accountService.getAll()
            //    .then(x => this.accounts = x);
        },
        components: {
            Navegador
        },
        methods: {
            //logout: accountService.logout,
            nuevaExperiencia(){
                console.log("creando experienciassss");
                router.push('/crear');
            },
            socilitarCategoria(){
                console.log("creando nueva experiencia:::,,");
                var request={
                    name:this.name,
                    description:this.description
                };
                console.log(request);
                categoryService.create(request).then(() => {
                    console.log("regresanddoooooo");
                    Swal.fire(
              'Se envio la solicitud',
              'En un momento nos comunicamos con usted para dar seguimiento a su solcitud',
              'success'
				);
                    router.push('../');
                })
                .catch(error => {
                    this.error = error;
                    this.loading = false;
                });
            },
            deleteAccount(id) {
                // show deleting spinner
                this.experiences = this.experiences.map(x => {
                    if (x.id === id) x.isDeleting = true;
                    return x;
                });
    
                // delete account
                //accountService.delete(id)
                //    .then(() => this.experiences = this.experiences.filter(x => x.id !== id));
            }
        }
    }
    </script>
<style>
    a.txt{
        display: block;
        text-align: center;
        text-decoration: none;
        color: #999;
        font-size: 0.7rem;
        transition: .3s;
    }

    .img2{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .img2 img{
        width: 400px;
    }

    @media screen and (max-width: 1000px){
	.formm{
		width: 290px;
	}

	.login-content h2{
        font-size: 2.4rem;
        margin: 8px 0;
	}

	.img2 img{
		width: 400px;
	}
}

@media screen and (max-width: 900px){
	.container{
		grid-template-columns: 1fr;
	}

	.img2{
		display: none;
	}

	.wave{
		display: none;
	}

	.login-content{
		justify-content: center;
	}
}

    

    a.txtCrear{
        display: block;
        text-align: center;
        text-decoration: none;
        color: #3B5998;
        font-size: 0.9rem;
        transition: .3s;
    }
    .title{
        color: #00369b;
        font-size: large;
    }
    .input-divf{
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .textForm{
        border-radius: 10px;
        border: 1px solid #0aaebd;
        font-size: medium;
        padding-right: 10px;
        padding-left: 10px;
    }
    .btnSiguiente{
        display: block;
        width: 100%;
        height: 35px;
        border-radius: 10px;
        outline: none;
        border: none;
        background-image: linear-gradient(to right, #0aaebd, #0aaebd, #68d9e4);
        background-size: 200%;
        font-size: 1rem;
        color: #fff;
        font-family: 'Poppins', sans-serif;
        margin: 1rem 0;
        cursor: pointer;
        transition: .5s;
    }
    .btnSiguiente:hover{
        background-position: right;
    }
</style>