<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-4 col-lg-4">
        <avatar-input :photo="photo"></avatar-input>
      </div>
      <div class="col-sm-12 col-md-8 col-lg-8">
        <div class="row">
          <input class="form-control textForm2" v-model="firstName" type="text" placeholder="Nombre">
        </div>
        <br>
        <div class="row">
          <input class="form-control textForm2" v-model="lastName" type="text" placeholder="Apellido paterno">
        </div>
        <br>
        <div class="row">
          <input class="form-control textForm2" v-model="motherLastName" type="text" placeholder="Apellido materno">
        </div>
      </div>
    </div>
    <hr>
    <br>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <label class="labelGener" for="idGener">Genero</label>
        <select v-model="gender" id="idGener" class="form-control textForm2">
          <option value="">Género</option>
          <option value="FEMALE">Femenino</option>
          <option value="MALE">Masculino</option>
          <option value="OTHER">Otro</option>
        </select>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <label class="labelBirdateUp" for="idbirthdateUp">Fecha de cumpleaños</label>
        <input class="form-control textForm2" id="idbirthdate" v-model="birthdate" type="date"
          placeholder="Fecha de cumpleaños">
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
        <input class="form-control textForm2" v-model="country" type="text" placeholder="Pais">
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
        <input class="form-control textForm2" v-model="city" type="text" placeholder="Ciudad">
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
        <input class="form-control textForm2" v-model="state" type="text" placeholder="Estado">
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-12">
        <input class="form-control textForm2" v-model="biography" type="text" placeholder="Biografia">
      </div>
    </div>
    <input class="btnSiguiente" @click="actualizarDatos" value="Actualizar">
  </div>
</template>
<script>
import { authenticationService, userService } from '@/_services';
import AvatarInput from '@/components/utils/AvatarInput'
import Swal from 'sweetalert2';
export default {
  name: 'Profile',
  data() {
    return {
      firstName: '',
      lastName: '',
      motherLastName: '',
      gender: '',
      birthdate: '',
      country: '',
      city: '',
      state: '',
      biography: '',
      photo: '',
      showMessage: false,
      src: null
    }
  },
  components: {
    AvatarInput
  },
  created() {
    this.getInfoUser();
  },
  methods: {
    actualizarDatos() {
      var request = {
        firstName: this.firstName,
        lastName: this.lastName,
        motherLastName: this.motherLastName,
        gender: this.gender,
        birthdate: this.birthdate,
        country: this.country,
        city: this.city,
        state: this.state,
        biography: this.biography
      };
      console.log(request);
      userService.update(request).then(() => {
        console.log("regresanddoooooo");
        Swal.fire(
          'Se actualizo correctamente',
          '',
          'success'
        );
      })
        .catch(error => {
          this.error = error;
          this.loading = false;
        });
    },
    browse() {
      this.$refs.file.click();
    },
    /*change(e) {
      this.photo = URL.createObjectURL(e.target.files[0])

      //this.emit('input', e.target.files[0]);
      /*let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      this.src.push({url:url, name:data.name, size:files[i].size, type:files[i].type});
      reader.onload = (e) => {
          this.src = e.target.result;
          console.log(this.src);
      }
    },*/
    milisegundosAFecha(value) {//yyyy-MM-dd".

      const fecha = new Date(value);
      const opcionesDeFormato =
      {
        timeZone: 'UTC',
        hour12: true,
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      };

      //const formato = new Intl.DateTimeFormat('es-MX', opcionesDeFormato);

      // Formatea la fecha y hora
      const fechaFormateada = new Intl.DateTimeFormat("fr-CA", opcionesDeFormato).format(fecha)

      return fechaFormateada;
    },
    async getInfoUser() {
      const res = await authenticationService.getInfoUser();
      console.log("DATOS DEL USUARIO");
      console.log(res);
      this.firstName = res.data.details.firstName,
        this.lastName = res.data.details.lastName,
        this.motherLastName = res.data.details.motherLastName,
        this.gender = res.data.details.gender,
        this.birthdate = this.milisegundosAFecha(res.data.details.birthdate),
        this.country = res.data.details.country,
        this.city = res.data.details.city,
        this.state = res.data.details.state,
        this.biography = res.data.details.biography,
        this.photo = res.data.details.profilePhoto.larger
    },
    openImageSelector() {
      const fileInput = this.$refs.fileInput;
      fileInput.click();
    },
    selectPhoto() {
      console.log("CAMBIANDO FOTO DE PERFIL");
    }
  }
}
</script>
<style>
.img-profile {
  width: 200px;
}



.centrardiv {
  text-align: center;
  display: flex;
  justify-content: center;
  /* Centrado horizontal */
  align-items: center;
  /* Centrado vertical */
}
</style>