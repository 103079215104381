<template>
  <div class="">
    <GaleryLetztrav v-if="viewGalery" :images="images" :id="currentId" @regresar="regresar" @guardar="guardar" ></GaleryLetztrav>
    <div v-if="!viewGalery"
      class="item-letztrav-container mainContent"
      ref="scrollContainer"
      @scroll="handleScroll"
    >
        <div v-for="experience in experiences" :key="experience.id" class="item-letztrav">
          <div class="item-letztrav-content">
            <span class="lugar">{{ experience.nameExperience }}</span>
            <span class="fecha">{{ experience.reservedDate | milisegundosAFecha }} | {{ experience.shortDirection }}</span>
          </div>
          <button class="custom-btn" type="button" @click="viewPhotos(experience)">
            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            Ver fotos
          </button>
          <!-- <button class="ver-fotos">Ver Fotos</button> -->
          <div v-if="loader" class="loadingClass">
              <span class="spinner-border spinner-border-lg align-center"></span>
            </div>
        </div>
      </div>
  </div>
</template>
<script>
    import { experienceService } from '@/_services';
    import GaleryLetztrav from '@/components/utils/GaleryLetztrav'
    export default {
      props: ['idUser'],
      data() {
        return {
          loader: false,
          scrollContainer: null,
          page: 0,
          itemsPerPage: 10,
          infoPagination: {},
          experiences: [],
          index: null,
          images: [],
          imagesLarge: [],
          viewGalery: false,
        }
      },
      filters: {
        milisegundosAFecha(value) {

            const fecha = new Date(value);
            const opcionesDeFormato = {
              timeZone: 'UTC',
              hour12: true,
              //weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric'
              // hour: '2-digit',
              // minute: '2-digit',
              // second: '2-digit'
            };

            const formato = new Intl.DateTimeFormat('es-MX', opcionesDeFormato);

            // Formatea la fecha y hora
            const fechaFormateada = formato.format(fecha);

            return fechaFormateada;
        }
      },

      mounted() {
        this.scrollContainer = this.$refs.scrollContainer;
        this.getExperiences();
      },
      destroyed() {
      },
      components: {
        GaleryLetztrav
      },
      methods: {
        guardar(id, urlImagenes, imagesToRemove, imagesToUpdate) {
          if(urlImagenes.length>0) {
            console.log("guardando nuevas fotos");
            console.log(id);
            console.log(urlImagenes);
            try {
              experienceService.addPhotos(id, {images: urlImagenes});
            } catch(error) {
              console.log(error);
            }
          }
          if(imagesToRemove.length>0) {
            console.log("borrando fotos");
            console.log(id);
            console.log(imagesToRemove);
            try {
              experienceService.removePhotos(id, {images: imagesToRemove});
            } catch(error) {
              console.log(error);
            }
          }
          console.log("actualizando imagenes de viajes");
          this.experiences.forEach(experience => {
            if (experience.id === id) {
              experience.images=[]
              imagesToUpdate.forEach(img => {
                experience.images.push({larger:img});
              });
            }
          });
        },
        async getExperiences(){
            try {
                this.loader = true;                
                const res = await experienceService.getPastExperiences(this.page, this.itemsPerPage, this.idUser);
                console.log('res:::');
                console.log(res);
                const newData = res.data;
                this.experiences = [...this.experiences, ...newData];
                this.page++;
                this.infoPagination = res.pageInfo;
                this.loader = false;
            } catch (error) {
                error.log(error);
                this.loader = false;
            }

        },
        viewPhotos(experience){
          this.$router.push('/experiences/photos/'+experience.id);
        },
        regresar() {
          this.viewGalery = false
        },

        handleScroll() {
            const containerHeight = this.scrollContainer.scrollHeight;
            const scrollTop = this.scrollContainer.scrollTop;
            const visibleHeight = this.scrollContainer.clientHeight;
            const hasReachedEnd = scrollTop + visibleHeight >= containerHeight;
            if (hasReachedEnd) {
                console.log("¡Has llegado al final del contenedor!");
                if (this.page > this.infoPagination.totalPages) {
                    return;
                }
                if (!this.loader) {
                    this.getExperiences();
                }
            }
        },
      },
      watch: {
      }
    }
</script>
<style>
    
  .loadingClass{
    text-align: center;
    margin: 0 auto;
  }
  .mainContent{
    overflow-y: scroll;
    height: 500px;
    padding-right: 5px;
  }

  @media screen and (max-width: 900px) {
    .mainContent{
    overflow-y: auto;
    height: 100%;
    padding-right: 5px;
  }
}



  .mainContent::-webkit-scrollbar {
    width: 8px; 
    background-color: rgba(202, 208, 212, 0.281);
  }

  .mainContent::-webkit-scrollbar-thumb {
    background-color: rgba(249, 160, 160, 0.459); 
    border-radius: 8px; 
  }

  
    .item-letztrav-container {
      display: flex;
      flex-direction: column;
    }
  
  
    .item-letztrav {
    height: 50px;
    background-color: rgb(224, 223, 223);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px;
    border-radius: 0;
  }
  
    .item-letztrav:not(:last-child) {
    border-bottom: 1px solid gray; /* Separación entre divs cuando hay más de dos */
  }
  
    .item-letztrav:first-child {
      border-radius: 20px 20px 0 0;
    }
  
    .item-letztrav:last-child {
      border-radius: 0 0 20px 20px;
      margin-bottom: 0;
    }
  
    .item-letztrav-container > div:only-child {
      border-radius: 20px 20px 20px 20px;
      margin-bottom: 0;
    }
    
  
    .item-letztrav-content {
      display: flex;
      flex-direction: column;
    }
  
    .lugar {
      font-weight: bold;
    }
  
    .fecha {
      color: gray;
    }
  
    .ver-fotos {
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 5px;
      padding: 5px 10px;
      cursor: pointer;
    }
    .custom-btn {
      background-color: #f9a0a0;
      color: white;
      border: none;
      border-radius: 5px;
      padding: 5px 10px;
      cursor: pointer;
    }
</style>