import { BehaviorSubject } from 'rxjs';
import axios from 'axios';

// const baseUrl = `${process.env.VUE_APP_API_URL}/api/experiences/past/6271e80409273c79e509849b`;
const baseUrl = `${process.env.VUE_APP_API_URL}/api/catalog/experiences`;
// experiences/past/0/15
// const baseUrlExperiences = `${process.env.VUE_APP_API_URL}/api/statistics/experience`;
const catalogExperienceSubject = new BehaviorSubject(null);


export const catalogExperienceService = {
    findByCategoryAndLocation,
    catalogExperience: catalogExperienceSubject.asObservable(),
    get catalogExperienceValue () { return catalogExperienceSubject.value; }
};

function findByCategoryAndLocation(category, longitude, latitude, page) {
    console.log("buscando experiencias");
    return axios.get(`${baseUrl}/find/`+category+`/`+page+`/15?longitude=`+longitude+`&latitude=`+latitude, {withCredentials: true})
        .then(response => response.data.data);
}
