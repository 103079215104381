import { BehaviorSubject } from 'rxjs';
import axios from 'axios';

const baseUrl = `${process.env.VUE_APP_API_URL}/api/core/notifications`;
const notificationSubject = new BehaviorSubject(null);


export const notificationService = {
    searchNotifications,
    checkNotification,
    notification: notificationSubject.asObservable(),
    get notificationValue () { return notificationSubject.value; }
};

function searchNotifications(pagina, itemsPerPage) {
    console.log("buscando notificaciones");
    console.log(pagina);
    console.log(itemsPerPage);
    return axios.get(`${baseUrl}/${pagina}/${itemsPerPage}`, {withCredentials: true})
        .then(response => response.data);
}


async function checkNotification(notificationId) {
    var params = {}
    const response = await axios.put(`${baseUrl}/check/${notificationId}`, params , {withCredentials: true});
    let responseData = response.data;
    return responseData;
}