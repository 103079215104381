<template>
    <router-view :idUser="idUser"></router-view>
</template>

<script>
export default {
    data() {
        return {
            idUser: this.$route.params.id
        }
    },
    methods: {
        async fileChange() {
        }
    },
    mounted() {
    },
    watch: {
        '$route.params.id': function (newId) {
            this.idUser = newId;
        }
    },
    props: {
        server: {
            type: String,
            default: '/api/upload',
        },
        maximo: {
            type: Number,
            default: 5,
        },
        actualGalery: Array
    }
}
</script>