<template>
  <div class="">
    <canvas width="100" height="100" v-bind:id="idCanvas"></canvas>
  </div>
</template>



<script>
var $=require('jquery')
import { experienceService } from '@/_services';
//import Chart from 'chart.js';
import Chart from 'chart.js/auto';
export default { 
    props:['urlDatos'],
    data () {
    return { 
        cargado:false,
        idCanvas:'doughnut-chart',
        myChart:null,
        datosCharts: [{data: [10, 20, 30]}] ,  
        labelsCharts:['Niños','Niñas'],
        opciones: {
            responsive: true,
            title: {
                display: true,
                position: "top",
                text: "Test",
                fontSize: 18,
                fontColor: "#111"
                },
            legend: {
                display: true,
                position: "bottom",
                labels: {
                    fontColor: "#333",
                    fontSize: 16
                }
            }
        }
    }
  },
  methods: {
    actualizarDatos(){
      this.cargarDatos();
    },
      cargarDatos () {
          var context=this;
          if(!context.urlDatos.includes('=null')){
            experienceService.getStadistics(this.urlDatos).then(function(res){
              console.log(res);
                context.opciones.title.text=res.data.titulo;
                if(context.cargado==false){
                  const ctx = $('#'+context.idCanvas);
                  context.myChart = new Chart(ctx, {
                    type: "doughnut",
                    data: {
                      datasets: res.data.lista,
                      labels: res.data.labels
                    },
                    options: context.opciones,
                  });
                  context.cargado=true;
                }else{
                  context.myChart.data.datasets=res.data.lista;
                  context.myChart.data.labels=res.data.labels;
                  context.myChart.options=context.opciones;
                  context.myChart.update();
                }
                
            }).catch(function(err)
        {
          if(err.response.status==403){
            context.$alert("Por su seguridad, la sesion ha expirado");
            
          }else{
            console.log("otra cosa");
          }
          console.log(err)
        
        });}
        },
      createChart(chartId) {
          const ctx = $('#'+chartId);
          this.myChart = new Chart(ctx, {
              type: "doughnut",
              data: {
                  datasets: this.datosCharts,
                  labels: this.labelsCharts
                  },
              options: this.opciones,
            });
        },
        create_UUID(){
			var dt = new Date().getTime();
			var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
				var r = (dt + Math.random()*16)%16 | 0;
				dt = Math.floor(dt/16);
				return (c=='x' ? r :(r&0x3|0x8)).toString(16);
			});
            return uuid;
        }
  },
  created () {
      this.idCanvas=this.create_UUID();
    this.cargarDatos(this);
  },
  mounted() {
      
  },
  watch:{
    urlDatos: function() {
      this.cargarDatos(this);
    }
  }
}
</script>
<style>
  
</style>