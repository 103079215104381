<template>
  <div class="">
    <div class="item-letztrav-container mainContent" ref="scrollContainer" @scroll="handleScroll">
      <div v-for="travel in travels" :key="travel.id" :class="loader ? 'item-letztrav-wait' : 'item-letztrav'">
        <div v-if="!loader" class="item-letztrav-content">
          <span class="lugar">{{ travel.to }}</span>
          <span class="fecha">{{ travel.toDate | milisegundosAFecha }}</span>
        </div>

        <button class="custom-btn" type="button" @click="viewPhotos(travel)">
          <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
          Ver fotos
        </button>
        <div v-if="loader" class="loadingClass">
          <span class="spinner-border spinner-border-lg align-center"></span>
        </div>
      </div>
      <!--<vue-gallery-slideshow :images="imagesLarge" :index="index" @close="index = null"></vue-gallery-slideshow>-->
    </div>
  </div>
</template>
<script>
import { travelService } from '@/_services';
export default {
  props: ['idUser'],
  data() {
    return {
      loader: false,
      scrollContainer: null,
      page: 0,
      itemsPerPage: 10,
      infoPagination: {},
      travels: [],
      index: null,
      images: [],
      //imagesLarge: [],
      viewGalery: false
    }
  },
  filters: {
    milisegundosAFecha(value) {

      const fecha = new Date(value);
      const opcionesDeFormato = {
        timeZone: 'UTC',
        hour12: true,
        //weekday: 'long', //DESCOMENTAR CUANDO ESTE LA APP MOVIL
        year: 'numeric',
        month: 'long',
        day: 'numeric'
        // hour: '2-digit',
        // minute: '2-digit',
        // second: '2-digit'
      };

      const formato = new Intl.DateTimeFormat('es-MX', opcionesDeFormato);

      const fechaFormateada = formato.format(fecha);

      return fechaFormateada;
    }
  },
  mounted() {
    this.scrollContainer = this.$refs.scrollContainer;
    this.getTravels();
  },
  destroyed() {
  },
  components: {
  },
  watch: {
    idUser() {
      this.page= 0;
      this.travels = [];
      console.log("buscando nuevam,ente los viajes");
      console.log("otroooo"+this.idUser);
      this.getTravels();
    },
  },
  methods: {
    guardar(id, urlImagenes, imagesToRemove, imagesToUpdate) {
      if (urlImagenes.length > 0) {
        console.log("guardando nuevas fotos");
        console.log(id);
        console.log(urlImagenes);
        try {
          travelService.addPhotos(id, { images: urlImagenes });
        } catch (error) {
          console.log(error);
        }
      }
      if (imagesToRemove.length > 0) {
        console.log("borrando fotos");
        console.log(id);
        console.log(imagesToRemove);
        try {
          travelService.removePhotos(id, { images: imagesToRemove });
        } catch (error) {
          console.log(error);
        }
      }
      console.log("actualizando imagenes de viajes");
      this.travels.forEach(travel => {
        if (travel.id === id) {
          travel.images = []
          imagesToUpdate.forEach(img => {
            travel.images.push({ larger: img });
          });
        }
      });

    },
    async getTravels() {
      try {
        this.loader = true;
        const res = await travelService.getPastTravels(this.page, this.itemsPerPage, this.idUser);

        const newData = res.data;
        this.travels = [...this.travels, ...newData];
        this.page++;
        this.infoPagination = res.pageInfo;
        this.loader = false;
      } catch (error) {
        console.log(error);
        this.loader = false;
      }

    },
    viewPhotos(travel) {
      console.log(travel);
      this.$router.push('/travels/photos/' + travel.id);
      //this.images = travel.images;
      //this.currentId = travel.id;
      //this.viewGalery = true
    },
    regresar() {
      this.viewGalery = false
    },

    handleScroll() {
      const containerHeight = this.scrollContainer.scrollHeight;
      const scrollTop = this.scrollContainer.scrollTop;
      const visibleHeight = this.scrollContainer.clientHeight;
      const hasReachedEnd = scrollTop + visibleHeight >= containerHeight;
      if (hasReachedEnd) {
        console.log("¡Has llegado al final del contenedor!");
        if (this.page > this.infoPagination.totalPages) {
          return;
        }
        if (!this.loader) {
          this.getTravels();
        }
      }
    },
  },
}
</script>
<style>
.loadingClass {
  text-align: center;
  margin: 0 auto;
}

.mainContent::-webkit-scrollbar {
  width: 8px;
  background-color: rgba(202, 208, 212, 0.281);
}

.mainContent::-webkit-scrollbar-thumb {
  background-color: rgba(249, 160, 160, 0.459);
  border-radius: 8px;
}

.item-letztrav-container {
  display: flex;
  flex-direction: column;
}


.item-letztrav-wait {
  height: 50px;
  background-color: rgb(224, 223, 223);
  display: flex;
  align-items: center;
  padding: 40px;
  border-radius: 0;
}

.item-letztrav {
  height: 50px;
  background-color: rgb(224, 223, 223);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  border-radius: 0;
}

.item-letztrav:not(:last-child) {
  border-bottom: 1px solid gray;
  /* Separación entre divs cuando hay más de dos */
}

.item-letztrav:first-child {
  border-radius: 20px 20px 0 0;
}

.item-letztrav:last-child {
  border-radius: 0 0 20px 20px;
  margin-bottom: 0;
}

.item-letztrav-container>div:only-child {
  border-radius: 20px 20px 20px 20px;
  margin-bottom: 0;
}


.item-letztrav-content {
  text-align: left;
  display: flex;
  flex-direction: column;
}

.lugar {
  font-weight: bold;
}

.fecha {
  color: gray;
}

.ver-fotos {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.custom-btn {
  background-color: #f9a0a0;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}
</style>