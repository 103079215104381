import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2';
import * as VueGoogleMaps from 'vue2-google-maps';
import vSelect from 'vue-select'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyC8fDTsTqSLtO8YowKRp9fH8K4V1F3lKyg',
    libraries: 'places'
  },
});

Vue.component('v-select', vSelect)

Vue.use(VueSweetalert2);
import "chart.js"
// setup fake backend
//import { fakeBackend } from './_helpers';
//fakeBackend();

import { 
    initFacebookSdk, 
    jwtInterceptor, 
    errorInterceptor, 
    router 
  } from './_helpers';
import App from './App.vue'


// enable interceptors for http requests
jwtInterceptor();
errorInterceptor();

// wait for facebook sdk to start app
initFacebookSdk().then(startApp);


Vue.config.productionTip = false

function startApp() {
  new Vue({
      router,
      render: h => h(App),
  }).$mount('#app');
}